// import Navbar from "../features/navbar/Navbar";
import Front from "../features/front/slider/Front";
import Footer  from "../features/footer/Footer";

function FrontPage() {
    return <div>
        {/* <Navbar > */}
        <Front></Front>
        {/* </Navbar> */}
        <Footer></Footer>
    </div>;
}

export default FrontPage;