import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import OrderDetailProducts from "./OrderDetailProducts";
import OrderDetailInfo from "./OrderDetailInfo";

const OrderDetailMain = () => {
  const { pk, id } = useParams();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        console.log(`Fetching data with pk: ${pk} and id: ${id}`);
        const response = await axios.get(`https://terracharms.com/get_order1/${pk}/${id}/`);
        const data = response.data;
        setOrderData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [pk, id]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    try {
        await axios.patch(`https://terracharms.com/patch_order/${pk}/${id}/`, { order_status: newStatus });      
      setOrderData((prevData) => ({
        ...prevData,
        order_status: newStatus,
      }));
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  if (!orderData) return <p>No data available.</p>;

  return (
    <section className="content-main">
      <div className="content-header">
        <Link to={`/admin/orders/${pk}`} className="btn btn-dark text-white">
          Back To Orders
        </Link>
      </div>

      <div className="card">
        <header className="card-header p-3 Header-green">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <span>
                <i className="far fa-calendar-alt mx-2"></i>
                <b className="text-white">{new Date(orderData.Time).toLocaleDateString()}</b>
              </span>
              <br />
              <small className="text-white mx-3">
                Order ID: {orderData.Order_id}
              </small>
            </div>
            <div className="col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center">
              <select
                className="form-select d-inline-block"
                style={{ maxWidth: "200px" }}
                onChange={handleStatusChange}
                value={orderData.order_status}
              >
                <option value="">Change status</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
              </select>
                {/* <option value="Confirmed">Confirmed</option> */}
            </div>
          </div>
        </header>
        <div className="card-body">
          <OrderDetailInfo order={orderData} />
          <div className="row">
            <div className="col-lg-9">
              <div className="table-responsive">
                <OrderDetailProducts order={orderData} />
              </div>
            </div>
        <div className="col-lg-3">
        <div className="box shadow-sm bg-light">
                <Link to={`/admin/shiprocket/${pk}/${id}`}>             
                <button className="btn btn-dark col-12">
                  ShipRocket 
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetailMain;
