
import React from "react";
import { Link,useParams } from "react-router-dom";
import $ from "jquery"; // Assuming you have jQuery installed
import axios from 'axios';

const Header = () => {
  const { pk } = useParams(); 
  const handleLogout = async () => {
    try {
      const response = await axios.patch(`https://terracharms.com/seller_logout/${pk}/`);
      
      if (response.status === 200) {
        window.location.href = '/sellerlogin'; // Redirect to seller login page after logout
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle the error as needed
    }
  };

  React.useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    <header className="main-header navbar">
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile me-auto"
          data-trigger="#offcanvas_aside"
        >
          <i className="md-28 fas fa-bars"></i>
        </button>
        <ul className="nav">
          <li className="dropdown nav-item">
            <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
              <img
                className="img-xs rounded-circle"
                src="/images/user.png"
                alt="User"
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to={`/seller/Profile/${pk}/`}>
                My profile
              </Link>
              <button className="dropdown-item text-danger" onClick={handleLogout}>
                Exit
              </button>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
