

import React, { useState, useEffect } from 'react';
import './OrderSuccessful.css';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { orderSuccessfulProvider } from '../../Providers/OrderSuccessfulProvider';
import { useParams } from "react-router-dom";

const OrderSuccessful = ({  orderId, message, redirectTo }) => {
    const [orderdata, setOrderData] = useState(null);
    const [ordersuccesscont, setordersuccesscont] = useRecoilState(orderSuccessfulProvider);
    const { pk} = useParams();

    useEffect(() => {
        if (orderId) {
            const fetchOrderDetails = async () => {
                try {
                    const response = await axios.get(`https://terracharms.com/order_invoice/${pk}/${orderId}/`);
                    console.log('Response from API:', response.data);
                    setOrderData(response.data);
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            };

            fetchOrderDetails();
        }
    }, [pk, orderId]);
    console.log({orderId})


    if (!orderdata) {
        return <div>Loading...</div>; // Add a loading state if orderdata is null or empty
    }
  

    return (
        <div className='OrdersSuccessful'>
            <button
                className='popup__close-btn'
                onClick={() => {
                    if (redirectTo === 'userorders') {
                        window.location.href = `/user/yourorders/${pk}`;
                    }
                    setordersuccesscont(false);
                }}
            >
                <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
                    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                </svg>
            </button>

            <div className='confirmationcont'>
                <div className='c1'>
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                   </svg>
                   <h2>{message}</h2>              
                    </div> 

                <div className='c2'>
                    <h2>Order Summary</h2>
                    <div>
                        <p>Order Number</p>
                        <p>{orderdata.Order_Number}</p>
                    </div>
                    <div>
                        <p>Order Date</p>
                        <p>{orderdata.Order_Date}</p>
                    </div>

                    <div>
                        <p>Name</p>
                        <p>{orderdata.Name}</p>
                    </div>

                    <div>
                        <p>Email</p>
                        <p>{orderdata.Email}</p>
                    </div>

                    <div>
                        <p>Payment Method</p>
                        <p>{orderdata.Payment_Method}</p>
                    </div>

                    <div>
                        <p>Shipping Address</p>
                        <p>{orderdata.Shipping_Address}</p>
                    </div>
                </div>

                <div className='c3'>
                    <table>
                        <thead>
                            <tr>
                                <th>Sno.</th>
                                <th>Product Image</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>

                        <tbody>
                            {orderdata.Order_items.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <p>{index + 1}</p>
                                    </td>
                                    <td>
                                        <img src={`https://terracharms.com/${item.product_image}`} alt="Product" />
                                    </td>
                                    <td>
                                        <p>{item.product_name}</p>
                                    </td>
                                    <td>
                                        <p> ₹ {item.actual_price}</p>
                                    </td>
                                    <td>
                                        <p>{item.quantity}</p>
                                    </td>
                                    <td>
                                        <p> ₹ {item.Total_price}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='totalcont'>
                    <div>
                        <p>Total</p>
                        <p> ₹ {orderdata.Total}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSuccessful;


