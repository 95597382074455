import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TopTotal from "./TopTotal";
import LatestOrder from "./LatestOrder";
import axios from "axios";

const Main = () => {
  const { pk } = useParams();
  const [data, setData] = useState({ total_sales: 0, total_orders: 0, total_products: 0 });

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/admin_dash/${pk}/`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };

    fetchAdminData();
  }, [pk]);

  return (
    <>
      <section className="content-main">
        <div className="content-header">
          <h2 className="content-title">Admin Dashboard</h2>
        </div>
        {/* Top Total Component */}
        <TopTotal data={data} />

        {/* Latest Order Component */}
        <div className="card mb-4 shadow-sm">
          <LatestOrder data={data.orders} />
        </div>
      </section>
    </>
  );
};

export default Main;
