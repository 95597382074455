import React from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const Product = ({ product, index, handleEditClick }) => {
  const { pk } = useParams();
// console.log(product)
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`https://terracharms.com/delete_admin_product/${pk}/${product.id}/`);
      console.log("Delete Response:", response.data);
      alert("Product deleted successfully");
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete product");
    }
  };

  return (
    <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
      <div className="card card-product-grid shadow-sm">
        <Link to="#" className="img-wrap">
          <img src={`https://terracharms.com${product.product_image}`} alt="Product" />
        </Link>
        <div className="info-wrap">
          <Link to="#" className="title text-truncate">
            {product.product_name}
          </Link>
          <div className="price mb-2">₹{product.discount_price}</div>
          <div className="row">
            <button
              id={`/admin/product/edit/${pk}/${product.id}`}
              className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
              onClick={() => handleEditClick(product.id)}
            >
              
              <i className="fas fa-pen"></i> Edit
            </button>
            <button
              onClick={handleDelete}
              className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
            >
              <i className="fas fa-trash-alt"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
