import React , {useState} from "react";
import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";

const MainCategories = () => {
  const [fetchTrigger, setFetchTrigger] = useState(false);

  const fetchCategories = () => {
    setFetchTrigger(!fetchTrigger);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Categories</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create category */}
            <CreateCategory fetchCategories={fetchCategories} />
            {/* Categories table */}
            <CategoriesTable key={fetchTrigger} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCategories;
