import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import './Sellercomponent.css';
const SellerComponent = () => {
  const [sellers, setSellers] = useState([]);
  const { pk } = useParams(); // Assuming `pk` is passed as URL parameter

  useEffect(() => {
    fetchSellers();
  }, [pk]);

  const fetchSellers = async () => {
    try {
      const response = await axios.get(`https://terracharms.com/get_seller/${pk}/`);
      setSellers(response.data);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    }
  };

  // Function to handle commission change for a specific seller
  const handleCommissionChange = (index, value) => {
    setSellers(prevSellers => {
      const newSellers = [...prevSellers];
      newSellers[index].commissionPercentage = value;
      return newSellers;
    });
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Sellers</h2>
        <div>
          <Link to="/sellersignup" className="btn btn-primary">
            <i className="material-icons md-plus"></i> Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-body">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
            {sellers.length > 0 ? (
              sellers.map((seller, index) => (
                <div className="col" key={index}>
                  <div className="card card-user shadow-sm">
                    <div className="card-header">
                      <img
                        className="img-md img-avatar mt-1"
                        src="/images/user.png"
                        alt="Seller pic"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title mt-5">{seller.Name}</h5>
                      <div className="card-text text-muted">
                        <p className="m-0">Total Sales: ₹{seller.total_sales}</p>
                        <p>
                          <a href={`mailto:${seller.email}`}>{seller.email}</a>
                        </p>
                        {/* Commission Box */}
                        <div className="commission-box">
                          <p className="text-black">
                            <input
                              type="number"
                              value={seller.commissionPercentage || ""}
                              onChange={(e) =>
                                handleCommissionChange(index, e.target.value)
                              }
                              placeholder="Enter %"
                            />
                            % Commission
                          </p>
                          <p className="text-black" >
                            Commission Value: ₹{((seller.total_sales * (seller.commissionPercentage || 0)) / 100).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No sellers found</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SellerComponent;
