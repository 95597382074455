import AdminOtp from "../features/auth/components/AdminOtp";


function AdminOtpPage() {
    return <div>
        <AdminOtp></AdminOtp>
    </div>;
}

export default AdminOtpPage;
