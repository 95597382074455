import SellerOtp2 from "../features/auth/components/SellerOtp2";


function SellerOtp2Page() {
    return <div>
        <SellerOtp2></SellerOtp2>
    </div>;
}

export default SellerOtp2Page;
