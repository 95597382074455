import Otp from "../features/auth/components/Otp";


function OtpPage() {
    return <div>
        <Otp></Otp>
    </div>;
}

export default OtpPage;


