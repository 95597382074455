import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Tracking.css"; // Import your CSS file

const OrderTracking = () => {
  const { pk ,id} = useParams(); // Assuming you're using React Router to get pk from URL params
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/track_order/${pk}/${id}/`);
        console.log(response.data)
        if (response.data) {
          setOrder(response.data);
        } else {
          throw new Error("Failed to fetch order details");
        }
      } catch (error) {
        console.error("Error fetching order:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [pk,id]);

  const cancelOrder = async () => {
    try {
      const response = await axios.post(`https://terracharms.com/cancel_order/${pk}/${id}/`);
      if (response.data) {
        alert("Order cancelled successfully");
      } else {
        throw new Error("Failed to cancel order");
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
      alert("Failed to cancel order");
    }
  };

  if (loading) {
    return <div className="order-tracking loading">Loading...</div>;
  }

  return (
    <div className="order-tracking">
      {error ? (
        <p className="error-message">Error: {error}</p>
      ) : (
        <>
          <h2>Order Details</h2>
          <p><strong>Order ID:</strong> {order.id}</p>
          <p><strong>Status:</strong> {order.status}</p>
          <p><strong>Total Amount:</strong> ₹{order.total_amount}</p>
          <p><strong>Items:</strong></p>
          <ul>
            {order.items.map((item) => (
              <li key={item.id}>
                {item.product_name} - ₹{item.price} - Quantity: {item.quantity}
              </li>
            ))}
          </ul>
          <div className="action-buttons">
            <button onClick={cancelOrder}>Cancel Order</button>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderTracking;
