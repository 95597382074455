import SellerForm from "../features/auth/components/SellerForm";
function SellerFormPage(){
    return(
        <div>
         <SellerForm></SellerForm> 
        </div>
    );

}

export default SellerFormPage;