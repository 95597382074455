import SellerOtp from "../features/auth/components/SellerOtp";


function SellerOtpPage() {
    return <div>
        <SellerOtp></SellerOtp>
    </div>;
}

export default SellerOtpPage;
