// src/components/Logout.js

import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const SellerLogout = () => {
  const navigate = useNavigate();
  const { pk } = useParams();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        const response = await axios.patch(`https://terracharms.com/seller_logout/${pk}/`);
        
        if (response.status === 200) {
          navigate('/sellerlogin');
        }
      } catch (error) {
        console.error('Error during logout:', error);
        // Handle the error as needed
      }
    };

    handleLogout();
  }, [pk, navigate]);

  return null;
};

export default SellerLogout;