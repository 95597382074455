import Navbar from "../features/navbar/Navbar";
import Homeslider from "../features/home/slider/Home";
import Footer  from "../features/footer/Footer";

function HomePage() {
    return <div>
        <Navbar>
        <Homeslider></Homeslider>
        </Navbar>
        <Footer></Footer>
    </div>;
}

export default HomePage;