
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const UserComponent = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { pk } = useParams(); // Get the admin ID from URL parameters

  useEffect(() => {
    fetchUsers();
  }, [pk]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`https://terracharms.com/get_user/${pk}/`);
      console.log("Fetched Users:", response.data); // Log the response data
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = async (searchTerm) => {
    setSearchTerm(searchTerm);
    try {
      const response = await axios.get("https://terracharms.com/search/", {
        params: { query: searchTerm },
      });
      console.log("Search Results:", response.data); // Log the response data
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Customers</h2>
        <div>
          <Link to="/signup" className="btn btn-primary">
            <i className="material-icons md-plus"></i> Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4">
        <header className="card-header">
         
        </header>

        <div className="card-body">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
            {users.length > 0 ? (
              users.map((user, index) => (
                <div className="col" key={index}>
                  <div className="card card-user shadow-sm">
                    <div className="card-header">
                      <img
                        className="img-md img-avatar"
                        src={user.profile_image ? `https://terracharms.com/${user.profile_image}` : "/images/user.png"}
                        alt="User"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title mt-5">{user.name}</h5>
                      <div className="card-text text-muted">
                        <p className="m-0">{user.role}</p>
                        <p>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No users found</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserComponent;
