import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import SOrderMain from "../components/orders/OrderMain";

const SOrderScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <SOrderMain />
      </main>
    </>
  );
};

export default SOrderScreen;
