import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Top = () => {
  const [selection, setSelection] = useState([]);
  const [product, setProduct] = useState('');
  const [products, setProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const { pk } = useParams();

  useEffect(() => {
    // Fetch all products first
    axios.get('https://terracharms.com/all_products/')
      .then(response => {
        const data = Array.isArray(response.data) ? response.data : [];
        console.log('Fetched products:', data);
        setProducts(data);

        // After fetching products, fetch top selections
        return axios.get(`https://terracharms.com/get_selection/${pk}/`);
      })
      .then(response => {
        const data = Array.isArray(response.data.top_selections) ? response.data.top_selections : [];
        console.log('Fetched selections:', data);
        setSelection(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [pk]);

  useEffect(() => {
    // Fetch subcategories for the dropdown
    axios.get('https://terracharms.com/get_sub_categories/')
      .then(response => {
        console.log('Fetched subcategories:', response.data);
        setSubcategories(response.data || []);
      })
      .catch(error => console.error('Error fetching subcategories:', error));
  }, []);

  const handleSaveProduct = () => {
    if (!product) {
      alert('Please select a product');
      return;
    }

    // Create new top selection
    axios.post(`https://terracharms.com/post_top_selection/${pk}/`, { product_name: product })
      .then(response => {
        setSelection([...selection, response.data]);
        setProduct('');
      })
      .catch(error => console.error('Error creating top selection:', error));
  };

  const handleDeleteClick = (topId) => {
    axios.delete(`https://terracharms.com/delete_selection/${pk}/${topId}/`)
      .then(() => setSelection(selection.filter(item => item.top_id !== topId)))
      .catch(error => console.error('Error deleting top selection:', error));
  };

  const handleProductSelect = (e) => {
    setProduct(e.target.value);
  };

  const findProductDetails = (productName) => {
    return products.find(prod => prod.product_name === productName);
  };

  return (
    <div className="product-form">
      <h2>Upload New Top Selection</h2>
      <div>
        <label htmlFor="product">Select Product:</label>
        <select
          id="product_name"
          name="product_name"
          value={product}
          onChange={handleProductSelect}
          className="form-control"
          required
        >
          <option value="">Select Product</option>
          {Array.isArray(products) && products.map((prod, index) => (
            <option key={index} value={prod.product_name}>
              {prod.product_name}
            </option>
          ))}
        </select>
      </div>

      <div className="product-upload mt-2">
        <button
          className='font-bold bg-green-300 px-6 py-2 mb-2 text-white rounded-md'
          onClick={handleSaveProduct}
        >
          Upload Product
        </button>
      </div>

      <div className="existing-selections">
        <h2 className='mb-2'>Top Selections</h2>
        {selection.length > 0 ? (
          selection.map(item => {
            const productDetails = findProductDetails(item.Product);

            return (
              <div key={item.top_id} className="product-item">
                {productDetails && productDetails.product_image ? (
                  <img 
                    src={`https://terracharms.com${productDetails.product_image}`} 
                    alt={productDetails.product_name} 
                    style={{ width: '100px', height: '100px' }} 
                  />
                ) : (
                  <p>No image available</p>
                )}
                <p>{productDetails ? productDetails.product_name : 'Unknown Product'}</p>
                <p>{productDetails ? `Price: ${productDetails.discount_price}` : ''}</p>
                <button onClick={() => handleDeleteClick(item.top_id)}>Delete</button>
              </div>
            );
          })
        ) : (
          <p>No top selections available.</p>
        )}
      </div>
    </div>
  );
};

export default Top;
