import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import Shiprocket from "../components/Shiprocket";

const SShiprocket = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
       <Shiprocket/>
      </main>
    </>
  );
};

export default SShiprocket;
