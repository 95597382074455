import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const EditCategory = ({ fetchCategories }) => {
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const csrftoken = Cookies.get('csrftoken');
  const { pk, id } = useParams();

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_one_category/${id}/`, {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        });
        console.log(response.data);
        const { Category, sub_categories } = response.data;
        setCategoryName(Category.category_name || '');
        setDescription(Category.description || '');
        setSubCategories(sub_categories || []);
      } catch (error) {
        console.error('Error fetching category details:', error);
      }
    };
    fetchCategoryDetails();
  }, [id, csrftoken]);

  const handleAddSubCategory = () => {
    setSubCategories([...subCategories, { sub_id: '', sub_category_name: '', description: '' }]);
  };

  const handleSubCategorySubmit = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(subCategories.map(async (subCategory) => {
        if (!subCategory.sub_id) {
          await axios.post(`https://terracharms.com/create_sub_category/${pk}/${id}/`, {
            sub_category_name: subCategory.sub_category_name,
          }, {
            headers: {
              'X-CSRFToken': csrftoken,
            },
          });
        }
      }));
      fetchCategories();
    } catch (error) {
      console.error("Error creating sub-categories:", error);
    }
  };

  const handleSubCategoryChange = (index, field, value) => {
    const newSubCategories = [...subCategories];
    newSubCategories[index][field] = value;
    setSubCategories(newSubCategories);
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('category_name', categoryName);
    formData.append('description', description);
    if (image) {
      formData.append('category_image', image);
    }

    try {
      // Update category details
      await axios.patch(`https://terracharms.com/edit_category/${pk}/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrftoken,
        },
      });

      // Update sub-categories
      await Promise.all(subCategories.map(async (subCategory) => {
        if (subCategory.sub_id) {
          const subCategoryFormData = new FormData();
          subCategoryFormData.append('sub_category_name', subCategory.sub_category_name);
          await axios.patch(`https://terracharms.com/edit_sub_category/${pk}/${subCategory.sub_id}/`, subCategoryFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-CSRFToken': csrftoken,
            },
          });
        }
      }));

      fetchCategories();
    } catch (error) {
      console.error('Error updating category or sub-categories:', error);
    }
  };

  return (
    <div className="col-md-12 col-lg-4 ml-96">
      <form onSubmit={handleCategorySubmit}>
        <div className="mb-4">
          <label htmlFor="category_name" className="form-label">Name</label>
          <input
            type="text"
            placeholder="Type here"
            className="form-control py-3"
            id="category_name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Images</label>
          <input
            className="form-control"
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Description</label>
          <textarea
            placeholder="Type here"
            className="form-control"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="form-label">Sub-Categories</label>
          {subCategories.map((subCategory, index) => (
            <div key={index} className="mb-2">
              <input
                type="text"
                placeholder="Sub-category Name"
                className="form-control mb-2"
                value={subCategory.sub_category_name}
                onChange={(e) => handleSubCategoryChange(index, 'sub_category_name', e.target.value)}
              />
            </div>
          ))}
          <button type="button" className="btn btn-secondary mt-2" onClick={handleAddSubCategory}>
            Add Sub-Category Fields
          </button>
        </div>

        <div className="d-grid">
          <button type="submit" className="btn btn-primary py-3">Edit Category</button>
        </div>
      </form>
      <form onSubmit={handleSubCategorySubmit} className="mt-4">
        <div className="d-grid">
          <button type="submit" className="btn btn-primary py-3">Create New Sub-Category</button>
        </div>
      </form>
    </div>
  );
};

export default EditCategory;
