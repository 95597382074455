import Payment from "../features/auth/components/Payment"


function PaymentPage() {
    return <div>
        <Payment></Payment>
    </div>;
}

export default PaymentPage;


