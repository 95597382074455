import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EditProductMain = ({ product, pk,id }) => {
  const navigate = useNavigate();
  const [subcategories, setSubcategories] = useState([]);
  const [formData, setFormData] = useState({
    product_name: "",
    discount_price: "",
    product_image: "",
    product_image2: "",
    product_image3: "",
    description: "",
    height: "",
    weight: "",
    size: "",
    colour: "",
    in_stock: "",
    stock_keeping_unit: "",
    actual_price: "",
    sub_category_name: "",
    Details: "",
    Highlights: ""

  });


  useEffect(() => {
    if (product) {
      setFormData({
        product_name: product.product_name || "",
        discount_price: product.discount_price || "",
        product_image: "",
        product_image2: "",
        product_image3: "",
        description: product.description || "",
        height: product.height || "",
        weight: product.weight || "",
        size: product.size || "",
        colour: product.colour || "",
        in_stock: product.in_stock || "",
        stock_keeping_unit: product.stock_keeping_unit || "",
        actual_price: product.actual_price || "",
        sub_category_name: product.sub_category_name || "",
        Details: product.Details || "",
        Highlights: product.Highlights || ""
      });
    }
  }, [product]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_sub_categories/');
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    for (const key in formData) {
      updatedFormData.append(key, formData[key]);
    }

    try {
      const response = await axios.patch(
        `https://terracharms.com/edit_seller_product/${pk}/${product.id}/`,
        updatedFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log("Edit Response:", response.data);
      alert("Product updated successfully");
      navigate(`/seller/products/${pk}`);
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product");
    }
  };

  return (
    <section className="content-main" style={{ maxWidth: "1200px" }}>
      <form onSubmit={handleSubmit}>
        <div className="content-header">
          <h2 className="content-title">Update Product</h2>
          <div>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-xl-8 col-lg-8">
            <div className="card mb-4 shadow-sm">
              <div className="card-body">
                <div className="mb-4">
                  <label htmlFor="product_name" className="form-label">
                    Product Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter product name"
                    className="form-control"
                    id="product_name"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="actual_price" className="form-label">
                    Actual Price
                  </label>
                  <input
                    type="number"
                    placeholder="Enter actual price"
                    className="form-control"
                    id="actual_price"
                    name="actual_price"
                    value={formData.actual_price}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="discount_price" className="form-label">
                    Discount Price
                  </label>
                  <input
                    type="number"
                    placeholder="Enter discount price"
                    className="form-control"
                    id="discount_price"
                    name="discount_price"
                    value={formData.discount_price}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="in_stock" className="form-label">
                    In Stock
                  </label>
                  <input
                    type="number"
                    placeholder="Enter stock quantity"
                    className="form-control"
                    id="in_stock"
                    name="in_stock"
                    value={formData.in_stock}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mt-2">
                  <input
                    type="text"
                    name="weight"
                    placeholder="Weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className="form-control mt-2"
                  />
                  <input
                    type="text"
                    name="height"
                    placeholder="Height"
                    value={formData.height}
                    onChange={handleInputChange}
                    className="form-control mt-2"
                  />
                  <select
                    className="form-select mt-2"
                    name="size"
                    value={formData.size}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Size</option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Large">Extra Large</option>
                  </select>
                  <select
                    className="form-select mt-2"
                    name="colour"
                    value={formData.colour}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Colour</option>
                    <option value="Red">Red</option>
                    <option value="Green">Green</option>
                    <option value="Black">Black</option>
                    <option value="White">White</option>
                    <option value="Brown">Brown</option>
                    <option value="Purple">Purple</option>
                    <option value="Beige">Beige</option>
                  </select>
                  <input
                    type="text"
                    name="stock_keeping_unit"
                    placeholder="SKU"
                    value={formData.stock_keeping_unit}
                    onChange={handleInputChange}
                    className="form-control mt-2"
                  />
                </div>

                <div className="mb-4">
                  <label className="form-label">Description</label>
                  <textarea
                    placeholder="Type here"
                    className="form-control"
                    rows="7"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="Details" className="form-label">Details</label>
                  <textarea
                    placeholder="Enter product details"
                    className="form-control"
                    rows="3"
                    name="Details"
                    value={formData.Details}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label htmlFor="Highlights" className="form-label">Highlights</label>
                  <textarea
                    placeholder="Enter product Highlights"
                    className="form-control"
                    rows="3"
                    name="Highlights"
                    value={formData.Highlights}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="form-label">Images</label>
                  <input
                    className="form-control mt-3"
                    type="file"
                    name="product_image"
                    onChange={handleFileChange}
                  />
                  <input
                    className="form-control mt-3"
                    type="file"
                    name="product_image2"
                    onChange={handleFileChange}
                  />
                  <input
                    className="form-control mt-3"
                    type="file"
                    name="product_image3"
                    onChange={handleFileChange}
                  />
                </div>

                <div>
                  <label htmlFor="sub_category_name">Select Subcategory:</label>
                  <select
                    id="sub_category_name"
                    name="sub_category_name"
                    value={formData.sub_category_name}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option key="default" value="">Select Subcategory</option>
                    {subcategories.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.sub_category_name}
                      </option>
                    ))}
                  </select>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditProductMain;
