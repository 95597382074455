import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import EditProductMain from "../components/products/EditproductMain";
import { useParams } from "react-router-dom";

const EditProductScreen = () => {
  const { pk, id } = useParams(); // Assuming you are using both `pk` and `id` from the URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_seller_product/${pk}/${id}/`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [pk, id]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        {product && <EditProductMain product={product} pk={pk} />} {/* Pass product details to form component */}
      </main>
    </>
  );
};

export default EditProductScreen;
