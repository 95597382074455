import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Shiprocket.css'; // Import the custom CSS for styling
import { useParams } from 'react-router-dom';

const Shiprocket = () => {
  const [password, setPassword] = useState('');
  const [channelId, setChannelId] = useState('');
  const [pickupLocation, setPickupLocation] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [addressId, setAddressId] = useState(null); // State to hold the address ID
  const { pk,id } = useParams(); // Fetch `id` and `pk` from URL params

  useEffect(() => {
    const fetchAddresses = async () => {
        try {
            const response = await axios.get(`https://terracharms.com/seller_address/${pk}/`);
            console.log(response.data)
            setAddresses(response.data);
        } catch (error) {
            console.error("Error fetching addresses:", error);
        }
    };

    fetchAddresses();
}, [pk]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure addressId is properly set
    const formData = {
      password,
      channelId: channelId || null,
      selectedAddress: selectedAddress || addressId || null, // Use selected or default addressId
      pickupLocation: pickupLocation || null
    };

    if (addressId) {
      axios.post(`https://terracharms.com/seller_order_place/${id}/${addressId}/`, formData)
        .then(response => {
          alert('Form submitted successfully!');
        })
        .catch(error => {
          console.error('Error submitting form:', error);
        });
    } else {
      console.error('Address ID is not set.');
    }
  };

  return (
    <div className="form-container">
      <h2>Seller Form</h2>
      <form onSubmit={handleSubmit} className="seller-form">
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="channelId">Channel ID (Optional)</label>
          <input
            type="text"
            id="channelId"
            value={channelId}
            onChange={(e) => setChannelId(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="sellerAddress">Seller Saved Address</label>
          <select
            id="sellerAddress"
            value={selectedAddress}
            onChange={(e) => {
              setSelectedAddress(e.target.value);
              setAddressId(e.target.value); // Update addressId when an address is selected
            }}
            className="form-control"
            required
          >
            <option value="">Select Address</option>
            {addresses.map((address, index) => (
              <option key={index} value={address.id}>
                {address.address}, {address.city}, {address.state}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="pickupLocation">Pickup Location (Optional)</label>
          <input
            type="text"
            id="pickupLocation"
            value={pickupLocation}
            onChange={(e) => setPickupLocation(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default Shiprocket;
