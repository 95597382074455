import Slider from "react-slick";
import "./index.css";
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import { useNavigate , useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
  
const Homeslider = (sub_category_name_id) => {
  const [products, setProducts] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);
  const [bestsellersProducts, setBestsellersProducts] = useState([]);
  const [callouts, setCallouts] = useState([]);
  const [userBanners, setUserBanners] = useState([]);
  const { pk } = useParams();
  const [promotions, setPromotions] = useState([]);
  const [headline, setHeadline] = useState('');
  const [insight, setInsight] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
   
    const isLoggedIn = false; // Change this according to your login check logic

    // Set a timeout for 7 seconds before showing the alert and navigating
    const timer = setTimeout(() => {
      if (!isLoggedIn) {
        window.alert('Please login first');
        navigate('/signup'); // Navigate to the signup page
      }
    }, 10000); // 10 seconds delay

    // Cleanup the timeout when the component unmounts or if dependencies change
    return () => clearTimeout(timer);
  }, [navigate]); // Dependency array with navigate ensures useEffect triggers properly

  // Fetch products
  useEffect(() => {
    const fetchLatestProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/latest_product/');
        setLatestProducts(response.data);
      } catch (error) {
        console.error('Error fetching latest products:', error);
      }
    };

    fetchLatestProducts();
  }, []);
  // Fetch bestsellers
  useEffect(() => {
    const fetchBestsellersProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/bestsellers_Domestic_Harmony/');
        setBestsellersProducts(response.data);
      } catch (error) {
        console.error('Error fetching bestsellers products:', error);
      }
    };

    fetchBestsellersProducts();
  }, []);
  // Fetch callouts
  useEffect(() => {
    const fetchCallouts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_user_selection/');
        const data = response.data;
        // Map the data to match the format of the static callouts
        const formattedData = data.map(item => ({
          name: item.product_name,
          description: item.description || 'No description available', // Assuming description is part of the product serializer
          imageSrc: `http://${item.product_image}`, // Construct the image URL
          imageAlt: `${item.product_name} image`, // Set an alt text
          href: `/user/productpage/${pk}/${item.id}/`, // Assuming product ID is available in the data
        }));
        setCallouts(formattedData);
      } catch (error) {
        console.error('Error fetching callouts:', error);
      }
    };

    fetchCallouts();
  }, []);

  // Fetch user banners
  const fetchUserBanners = async () => {
  axios.get(`https://terracharms.com/get_user_banner/`)
      .then(response => setUserBanners(response.data))
      .catch(error => console.error('Error fetching banners:', error));
};
useEffect(() => {
  axios.get('https://terracharms.com/get_user_promotion/')
    .then(response => {
      const data = response.data;
      setPromotions(data);

      // Use the first promotion for headline and insight if available
      if (data.length > 0) {
        setHeadline(data[0].Headline || 'Discover the Art of Living');
        setInsight(data[0].Insight || 'This year, clearing and creating living spaces that are visually appealing and more attractive to the human eye.');
      }
    })
    .catch(error => {
      console.error('There was an error fetching the promotions!', error);
    });
}, []);

  // Fetch recent searched products
    useEffect(() => {
      const fetchRecentSearched = async () => {
        try {
          const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
          setProducts(response.data);
        } catch (error) {
          console.error('Error fetching recent searched products:', error);
        }
      };  
      fetchRecentSearched();
    }, [pk]);

    const handlerecentsearchedclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };

    const handlebestsellerclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };
    const handlelatestclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };

  useEffect(() => {
    fetchUserBanners();  // Ensure pk is provided in URL or set it dynamically
  },[]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    fade: true,
    arrows: true,
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 3000,  // Slide transition time in milliseconds
  };
  const settings1 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Show 7 products per slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    // <SlArrowRight />
  };
  // Handler to navigate to the AllProduct page for banners
  const handleBannerClick = (sub_category_name_id) => {
    navigate(`/user/allproduct/${pk}/${sub_category_name_id}`);
  };
  
  // Handler to navigate to the AllProduct page for promotions
  const handlePromotionClick = (sub_category_name_id) => {
    navigate(`/user/allproduct/${pk}/${sub_category_name_id}`);
  };

// const handleProductClick = (productId, productPk) => {
//   navigate(`/get_product/${productPk}/${productId}/`);
// };
const handleProductClick = async (id) => {
  try {
    // Hitting the Django API to get product data
    const response = await axios.get(`/get_product/${pk}/${id}/`);
    
    if (response.status === 200) {
      // If successful, navigate to the product page
      navigate(`/user/productpage/${pk}/${id}/`);
    } else {
      console.error("Failed to retrieve product details.");
    }
  } catch (error) {
    console.error("Error fetching product details:", error);
  }
};
  return (
    <>
<section className="homeslider">
        <div className="relative overflow-hidden mt-4 bg-gray-200 mb-4">
          <div className="container-h-40 fluid w-100 mt-3 mr-0">
            <Slider {...settings}>
              {userBanners.length > 0 ? (
                userBanners.map((banner) => (
                  <div
                  key={banner.sub_id} // Ensure unique key
                  className="item h-60 cursor-pointer"
                  onClick={() => handleBannerClick(banner.sub_category_name_id)} // Pass sub_id
                >
                  <img
                    src={`https://terracharms.com${banner.Banner_image}`}
                    className="w-100 h-60"
                    alt={`Banner ${banner.sub_category_name_id}`}
                  />
                  </div>
                ))
              ) : (
                <p>No banners available</p>
              )}
            </Slider>
          </div>
        </div>
      </section>

      <div className="relative overflow-hidden mt-4 bg-green-200 mb-4">
        <div className="pb-40 pt-16">
          <div className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                {headline}
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                {insight}
              </p>
            </div>
            <div>
  <div
    aria-hidden="true"
    className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
  >
    <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
      <div className="flex items-center space-x-6 lg:space-x-8">
        {/* Dynamically generate promotion images */}
        {promotions.length > 0 ? (
          promotions.map((promotion, index) => (
            <div
              key={index}
              className="grid grid-cols-3 gap-x-6 gap-y-10 mb-16 h-full w-full object-cover object-center"
            >
              {[
                promotion.promotion_image1,
                promotion.promotion_image2,
                promotion.promotion_image3,
                promotion.promotion_image4,
                promotion.promotion_image5,
              ].map((image, idx) => (
                image ? (
                  <div
                  key={idx}
                  className="h-48 w-40 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 cursor-pointer"
                  onClick={() => handlePromotionClick(promotion.sub_category_name_id)} // Pass sub_category_name_id
                >
                    <img
                      alt={`Promotion ${idx + 1}`}
                      src={`https://terracharms.com${image}`}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                ) : null
              ))}
            </div>
          ))
        ) : (
          <p>No promotions available</p>
        )}
      </div>
    </div>
  </div>

  {/* The Shop Collection button should also trigger the same function */}
  {promotions.length > 0 && (
    <a
      href="#"
      className="inline-block mt-16 rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
      onClick={(e) => {
        e.preventDefault(); // Prevent default anchor behavior
        handlePromotionClick(promotions[0].sub_category_name_id); // Assuming you want to use the first promotion's ID
      }}
    >
      Shop Collection
    </a>
  )}
</div>
          </div>
        </div>
      </div>


    <div className="relative overflow-hidden mt-4 bg-gray-200 mb-6">
      <div className="pt-10">
        <h2 className="text-2xl font-bold text-left ml-4 text-gray-900">Latest Products </h2>
        <div className="mt-6 grid grid-row-1 sm:grid-cols-1 lg:grid-row-4">
        <Slider {...settings1}>
        {latestProducts.length > 0 ? (
                latestProducts.map((product) => (
                  <div key={product.id} className="group relative px-3" 
                  onClick={() => handlelatestclick(product.id, product.pk)} >
                    <div className="aspect-h-1 aspect-w-1 w-full rounded-md bg-black">
                      <img
                        alt={product.product_name}
                        src={`https://terracharms.com${product.product_image}`}
                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                      />
                    </div>
                    <div className="mt-2 mb-4 flex justify-between">
                      <div>
                        <h3 className="text-sm text-gray-700">
                          <a href="">
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.product_name}
                          </a>
                        </h3>
                 
                      </div>
                
                    </div>
                  </div>
                ))
              ) : (
                <p>No latest products available</p>
              )}
        </Slider>
        </div>
      </div>
    </div>
    <div className="relative overflow-hidden bg-gray-200">
  {/* <div className="bg-white"> */}
      <div className="pt-10">
        <h2 className="text-2xl font-bold text-gray-900 text-left ml-4">BestSeller on Domestic Harmony </h2>
        <div className="mt-6 grid grid-row-1 sm:grid-cols-1 lg:grid-row-4">
        <Slider {...settings1}>
           {bestsellersProducts.length > 0 ? (
                bestsellersProducts.map((product) => (
                  <div key={product.id} className="group relative px-3"
              onClick={() => handlebestsellerclick(product.id, product.pk)} // Handle click
                  >
                    <div className="aspect-h-1 aspect-w-1 w-full rounded-md bg-black">
                      <img
                        alt={product.product_name}
                        src={`https://terracharms.com${product.product_image}`}
                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                      />
                    </div>
                    <div className="mt-2 mb-4 flex justify-between">
                      <div>
                        <h3 className="text-sm text-gray-700">
                          <a href="">
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.product_name}
                          </a>
                        </h3>
                      </div>
                   
                    </div>
                  </div>
                ))
              ) : (
                <p>No bestsellers available</p>
              )}
        </Slider>
        </div>
      {/* </div> */}
    </div>
    </div>
    <div className="relative overflow-hidden mt-4 bg-cyan-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-4 mb-4 lg:max-w-none">
          <h2 className="text-2xl font-bold text-gray-900 text-left ml-2">Top Selection</h2>

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.length > 0 ? (
              callouts.map((callout) => (
                <div key={callout.name} className="group relative"
              onClick={() => handleProductClick(callout.id, callout.pk)} // Handle click
                >
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                    <img
                      alt={callout.imageAlt}
                      src={callout.imageSrc}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))
            ) : (
              <p>No selections available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default Homeslider;
