import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Cart.css";
import "./Progress.css";
import "./CartContainer.css";
import "./ShippingContainer.css";
import "./PaymentContainer.css";
import "./OrderSucessfull.css";
import { useRecoilState } from "recoil";
import { orderSuccessfulProvider } from "../../user/Providers/OrderSuccessfulProvider";
import OrderSuccessful from "../../user/COMPONENTS/Order/OrderSuccessful";
import { Link, Navigate, useParams } from "react-router-dom";
const UCart = (orderId) => {
  
  const [cartdata, setcartdata] = React.useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setshipping] = React.useState(0);
  const [active, setactive] = React.useState(1);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("C.O.D");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [tax, settax] = React.useState(0);
  const [orderdata, setOrderData] = useState(null);
  
  const [deliverydate, setdeliverydate] = React.useState(
    new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const [cartItems, setCartItems] = useState([]);
  const {pk,id} = useParams();
  

  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    fetchUserAddresses();
  }, []);

  const fetchCartItems = () => {
    axios.get(`https://terracharms.com/get_user_cartitem/${pk}/`)
      .then(response => {
      console.log("hello")
        setCartItems(response.data);
        calculateSubtotal(response.data);
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
      });
  };

  const calculateSubtotal = (items) => {
    const subtotal = items.reduce((acc, item) => acc + item.Total, 0);
    setSubtotal(subtotal);
  };

  const addToCart = (id, quantity) => {
    axios.patch(`https://terracharms.com/add_to_cart/${pk}/${id}/`, { quantity })
      .then(response => {
        console.log(response.data.msg);
        fetchCartItems();
      })
      .catch(error => {
        console.error('Error adding item to cart:', error);
      });
  };

  const removeFromCart = (id) => {
    axios.delete(`https://terracharms.com/delete_items/${pk}/${id}/`)
      .then(response => {
        console.log(response.data.msg);
        fetchCartItems();
      })
      .catch(error => {
        console.error('Error removing item from cart:', error);
      });
  };

  const changeQuantity = (id, newQuantity) => {
    axios.patch(`https://terracharms.com/quantity/${pk}/${id}/`, { quantity: newQuantity })
      .then(response => {
        console.log(response.data.msg);
        fetchCartItems();
      })
      .catch(error => {
        console.error('Error changing item quantity:', error);
      });
  };

  const checklogin = () => {
    return true;
  };
  const [selectedorderid, setselectedorderid] = useState(0);
  const [response, setrespnose] = useState();
  const [ordersuccesscont, setordersuccesscont] = useRecoilState(
    orderSuccessfulProvider
  );
useEffect(() => {
  fetchUserAddresses();
}, []);

// Function to fetch user addresses
const fetchUserAddresses = () => {
   axios.get(`https://terracharms.com/user_address/${pk}/`)
    .then(response => {
      setSavedAddresses(response.data); // Assuming response.data is an array of addresses
      console.log(response.data)
    })
    .catch(error => {
      console.error('Error fetching user addresses:', error);
    });
};
const handleRadioChange = (event) => {
  setSelectedAddressId(parseInt(event.target.value)); // Update selected address ID on radio button change
};

const handlePaymentMethodChange = (event) => {
  setSelectedPaymentMethod(event.target.value);
};

const handleNextButtonClick = () => {
  // Perform actions when Next button is clicked, using selectedAddressId
  if (selectedAddressId !== null) {
    console.log(`Next button clicked for address ID ${selectedAddressId}`);
    // Example: Navigate to next step or perform other logic
    checklogin() && setactive(3); // Example of setting active step to 3
  } else {
    console.error("Please select an address");
    // Optionally show an error or handle the case where no address is selected
  }
};
const handlePaymentChange = (event) => {
  setSelectedPaymentMethod(event.target.name);
};
const handleNextButtonClick2 = () => {
  if (selectedAddressId !== null) {
    const url = selectedPaymentMethod === "C.O.D"
      ? `https://terracharms.com/placeordercod/${pk}/${selectedAddressId}/`
      : `https://terracharms.com/online_payment/${pk}/${selectedAddressId}/`;

    console.log(`Next button clicked for address ID ${selectedAddressId} and payment method ${selectedPaymentMethod}`);    
    axios.post(url, {
      payment_method: selectedPaymentMethod
    })
    .then(response => {
     
      
      if (selectedPaymentMethod === "Prepaid") {
        const paymentUrl = response.data;
        console.log("Redirecting to payment URL:", paymentUrl);
        window.location.href = paymentUrl;  // Redirect to the payment URL
      } else {
        console.log("Order placed:", response.data);
      const orderId = response.data.id;
      console.log(orderId); // Print order ID locally
      setSelectedOrderId(orderId); 
      setactive(4); // Proceed to the next step if needed
      }
    })
    
    .catch(error => {
      console.error("Error placing order:", error);
    });
  } else {
    console.error("Please select an address");
  }
};
  return (
    <div>
      <div className="cart">
        <div className="progress">
          {active == 1 ? (
            <div
              className="c11"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                />
              </svg>
              <span>My Cart</span>
            </div>
          ) : (
            <div
              className="c1"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                />
              </svg>
              <span>My Cart</span>
            </div>
          )}

          {active == 2 ? (
            <div
              className="c11"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(2);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>

              <span>Shipping</span>
            </div>
          ) : (
            <div
              className="c1"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(2);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>

              <span>Shipping</span>
            </div>
          )}

          {active == 3 ? (
            <div
              className="c11"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(3);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>

              <span>Payment</span>
            </div>
          ) : (
            <div
              className="c1"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(3);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>

              <span>Payment</span>
            </div>
          )}
          

        
          {active == 4 ? (
            <div
              className="c11"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(4);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>

              <span>Success</span>
            </div>
          ) : (
            <div
              className="c1"
              onClick={() => {
                cartdata.length > 0 && checklogin() && setactive(4);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>

              <span>Success</span>
            </div>
          )}
    
    </div>
  

        {active === 1 ? (
        <div className="cartcont">
          {cartItems.length > 0 ? (
            <table className="carttable">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index} className="cartitemrow">
                    <td data-label="Product">
                      <div className="cartproduct">
                        <img src={`https://terracharms.com/${item.product_image}`} alt="Product" />
                    
                      </div>
                    </td>
                    <td data-label="Quantity">
                      <div className="quantity">
                        <button className="minus" onClick={() => changeQuantity(item.id, item.quantity - 1)}>-</button>
                        <span>{item.quantity}</span>
                        <button className="plus" onClick={() => changeQuantity(item.id, item.quantity + 1)}>+</button>
                      </div>
                    </td>
                    <td data-label="Price">₹{item.actual_price.toFixed(2)}</td>
                    <td data-label="Total">₹{item.Total.toFixed(2)}</td>
                    <td data-label="Remove">
                      <div className="delbtn" onClick={() => removeFromCart(item.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td className="totaltableleft">Sub-Total</td>
                  <td className="totaltableright">₹{subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="totaltableleft">Total</td>
                  <td className="totaltableright">₹{(subtotal + shipping).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="emptycart">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
              </svg>
              <p>Your cart is empty</p>
            </div>
          )}
        </div>
      ) : null}
      {active === 2 && (
        <div className="shippingcont">
          <div className="previous">
            <h2 className="mainhead1">Previous Saved Address</h2>
            {savedAddresses.length > 0 ? (
              savedAddresses.map((address) => (
                <div className="shippingcont" key={address.id}>
                  <input
                    type="radio"
                    name="address"
                    id={address.id}
                    value={address.id}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor={address.id} className="ml-4">
                    <span>{address.name}</span>
                    <span>{address.address}</span>
                    <span>
                      {address.city}, {address.state}, {address.country},{" "}
                      {address.zipcode}
                    </span>
                  </label>
                </div>
              ))
            ) : (
              <div className="empty-address">
                <p>No addresses found</p>
              </div>
            )}
            <h4>OR</h4>
            <br />
            <Link to={`/user/address/${pk}`}>
              <button className="font-bold bg-blue-300 px-6 py-3 text-white rounded-md">
                Add New Address
              </button>
            </Link>
            <br />
          </div>
        </div>
      )}
         {active === 3 && (
        <div className="paymentcont">
          <h2 className="mainhead1">Select Payment Method</h2>
          <div className="paymenttypes">
          <div className="c1">
    <input 
      type="radio" 
      name="payment" 
      value="C.O.D" 
      id="payment1" 
      onChange={handlePaymentMethodChange} 
    />
    <img
      className="w-20px"
      src="https://cdn-icons-png.flaticon.com/512/10749/10749023.png"
      alt="Cash on Delivery"
    />
  </div>
  <div className="c1">
    <input 
      type="radio" 
      name="payment" 
      value="Prepaid" 
      id="payment2" 
      onChange={handlePaymentMethodChange} 
    />
    <img className="w-100"
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQo5eM3BtHMgnNaWwlOWTqWQ1smt3cqb2KDA&s"
      alt="Prepaid"
    />
  </div>
          </div>
        </div>
      )}
       {active == 4 && (
          <div className="ordersuccessfull">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>

            <h2 className="mainhead1">Order Placed Successfully</h2>
            <p>Thank you for shopping with us</p>
            <span>Order ID: {selectedOrderId}</span>
          </div>
        )}


{active == 1 && (
          <div className="btns">
            <button
              className="nextbtn"
              onClick={() => {
                checklogin() && setactive(2);
              }}
            >
              Next
             
            </button>
           
          </div>
        )}
          {active === 2 && (
        <div className="btns">
          <button
            className="backbtn"
            onClick={() => {
              checklogin() && setactive(1);
            }}
          >
            Back
          </button>

          {/* Pass selectedAddressId to handleNextButtonClick */}
          <button
            className="nextbtn"
            onClick={handleNextButtonClick}
          >
            Next
          </button>
          
        </div>
      )}

{active === 3 && (
        <div className="btns">
        <button
          className="backbtn"
          onClick={() => {
            checklogin() && setactive(2);
          }}
        >
          Back
        </button>

          {/* Pass selectedAddressId to handleNextButtonClick */}
          <button
            className="nextbtn"
            onClick={handleNextButtonClick2}
          >
            Next
          </button>
          
        </div>
      )}
         {active == 4 && (
          <div className="btns">
            <button
              className="nextbtn"
              onClick={() => {
                setselectedorderid(orderId);
                setordersuccesscont(true);
              }}
            >
              View Invoice
            </button>
          </div>
        )}

            {ordersuccesscont && (
                <OrderSuccessful
                    orderId={selectedOrderId}
                    message={`Order Placed Successfully, Order ID: ${selectedOrderId}`}
                    redirectTo="userorders"
                />
            )}
      </div>
    </div>
  );
};

export default UCart;

