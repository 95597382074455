import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [newBanner, setNewBanner] = useState(null); // Only one banner at a time
  const [editBannerId, setEditBannerId] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const { pk } = useParams();

  useEffect(() => {
    // Fetch existing banners
    axios.get(`https://terracharms.com/get_banner/${pk}/`)    
      .then(response => setBanners(response.data))
      .catch(error => console.error('Error fetching banners:', error));
  }, [pk]);

  useEffect(() => {
    // Fetch subcategories for the dropdown
    axios.get('https://terracharms.com/get_sub_categories/')
      .then(response => {
        console.log('Fetched subcategories:', response.data);
        setSubcategories(response.data || []);
      })
      .catch(error => console.error('Error fetching subcategories:', error));
  }, [pk]);

  // Handle adding or editing a banner
  const handleBannerChange = (e) => {
    setNewBanner(e.target.files[0]);
  };

  const handleSaveBanner = () => {
    if (!newBanner || !selectedSubcategory) {
      alert('Please select a subcategory and upload a banner image');
      return;
    }

    const formData = new FormData();
    formData.append("Banner_image", newBanner);
    formData.append("sub_category_name", selectedSubcategory);

    if (editBannerId) {
      // Edit existing banner
      axios.post(`https://terracharms.com/post_banner/${pk}/`, formData)
        .then(response => {
          const updatedBanners = banners.map(banner =>
            banner.id === editBannerId ? response.data : banner
          );
          setBanners(updatedBanners);
          setEditBannerId(null);
          setNewBanner(null);
          setSelectedSubcategory('');
        })
        .catch(error => console.error('Error updating banner:', error));
    } else {
      // Create new banner
      axios.post(`https://terracharms.com/post_banner/${pk}/`, formData)
        .then(response => {
          setBanners([...banners, response.data]);
          setNewBanner(null);
          setSelectedSubcategory('');
        })
        .catch(error => console.error('Error creating banner:', error));
    }
  };

  const handleDeleteClick = (bannerId) => {
    axios.delete(`https://terracharms.com/delete_banner/${pk}/${bannerId}/`)
      .then(() => setBanners(banners.filter(banner => banner.id !== bannerId)))
      .catch(error => console.error('Error deleting banner:', error));
  };

  return (
    <div className="banner-form">
      <h2>Upload New Banner</h2>
      
      <div>
        <label htmlFor="selectedSubcategory">Select Subcategory:</label>
        <select
          id="sub_category_name"
          name="sub_category_name"
          value={selectedSubcategory}
          onChange={(e) => setSelectedSubcategory(e.target.value)}
          className="form-control"
          required
        >
          <option value="">Select Subcategory</option>
          {Array.isArray(subcategories) && subcategories.map((subcategory, index) => (
            <option key={index} value={subcategory.sub_category_name}>
              {subcategory.sub_category_name}
            </option>
          ))}
        </select>
      </div>

      <div className="banner-upload mt-2">
        <input
          type="file"
          onChange={handleBannerChange}
          accept="image/*"
          className='mb-2'
        />
        <button
          className='font-bold bg-green-300 px-6 py-2 mb-2 text-white rounded-md'
          onClick={handleSaveBanner}
        >
          {editBannerId ? 'Save Changes' : 'Upload Banner'}
        </button>
      </div>

      {newBanner && (
        <div className="preview-banner">
          <h3>Preview Banner</h3>
          <img
            src={URL.createObjectURL(newBanner)}
            alt="Preview Banner"
          />
        </div>
      )}

      <div className="existing-banners">
        <h2 className='mb-2'>Existing Banners</h2>
        {banners.map(banner => (
          <div key={banner.id} className="banner-item">
            <img src={`https://terracharms.com${banner.Banner_image}`} alt="Banner" />
            <button onClick={() => handleDeleteClick(banner.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
