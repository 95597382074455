import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const OrderDetailProducts = () => {
  const { pk, id } = useParams();
  const [orderData, setOrderProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderProduct = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_order3/${pk}/${id}/`);        
        setOrderProduct(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderProduct();
  }, [pk, id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <table className="table border table-lg">
      <thead>
        <tr>
          <th style={{ width: "40%" }}>Product</th>
          <th style={{ width: "20%" }}>Unit Price</th>
          <th style={{ width: "20%" }}>Quantity</th>
          <th style={{ width: "20%" }} className="text-end">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Link className="itemside" to="#">
              <div className="left">
                <img
                  src={`https://terracharms.com/${orderData.Image}`}
                  alt="product"
                  style={{ width: "40px", height: "40px" }}
                  className="img-xs"
                />
              </div>
              <div className="info">
                {orderData["Product Name"]}
              </div>
            </Link>
          </td>
          <td>₹{orderData.Price}</td>
          <td>1</td>
          <td className="text-end">₹{orderData.Price}</td>
        </tr>

        <tr>
          <td colSpan="4">
            <article className="float-end">
              <dl className="dlist">
                <dt>Item Price:</dt>
                <dd>₹{orderData.Price}</dd>
              </dl>
              <dl className="dlist">
                <dt>Grand total:</dt>
                <dd>
                  <b className="h5">₹{parseFloat(orderData.Price)}</b>
                </dd>
              </dl>
              <dl className="dlist">
                <dt className="text-muted">Status:</dt>
                <dd>
                <dd>
                  <span className="badge rounded-pill alert alert-success text-success text-white">
                    {orderData.Payment_Method === "Card" ? "Payment done via Card" : "Payment done via Cash on Delivery"}
                  </span>
                </dd>
                </dd>
              </dl>
            </article>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailProducts;
