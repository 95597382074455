import './ProductCard.css';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
const ProductCard = ({ product }) => {
  const { pk , id } = useParams();  // Get the pk from the route parameters
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(1);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_all_sub_products/${pk}/${id}/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [pk,id]);

  const addToCart = async () => {
    try {
      const response = await axios.patch(
        `https://terracharms.com/add_to_cart/${pk}/${id}/`,
        
      );
      console.log(response.data.id)
      if (!response.data) {
        throw new Error("Failed to add item to cart");
      }

      toast.success("Item added to cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };


  return (
    <div className="product">
      <div className="s1">
        <img src={`https://terracharms.com${product.product_image}`} alt={product.product_name} />
      </div>
      <div className="s2">
        <h3>
          ₹ {product.discount_price }
          <span>₹{product.actual_price}</span>
        </h3>
        <p>{product.product_name}</p>
      </div>
      <div className="s3">
        <p>{product.size}</p>
      </div>
      <div className="addbtn">
        <Link to={`/user/productpage/${pk}/${product.id}`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
