import Slider from "react-slick";
import "./index.css";
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import { useNavigate , useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../navbar/Navbar"; // Ensure this import is correct
const Front = (sub_category_name_id) => {
  const [latestProducts, setLatestProducts] = useState([]);
  const [bestsellersProducts, setBestsellersProducts] = useState([]);
  const [callouts, setCallouts] = useState([]);
  const [userBanners, setUserBanners] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [headline, setHeadline] = useState('');
  const [insight, setInsight] = useState('');
  const { pk } = useParams();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Hook to programmatically navigate
  // Fetch products
  useEffect(() => {
    const fetchLatestProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/latest_product/');
        setLatestProducts(response.data);
      } catch (error) {
        console.error('Error fetching latest products:', error);
      }
    };

    fetchLatestProducts();
  }, []);
  // Fetch bestsellers
  useEffect(() => {
    const fetchBestsellersProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/bestsellers_Domestic_Harmony/');
        setBestsellersProducts(response.data);
      } catch (error) {
        console.error('Error fetching bestsellers products:', error);
      }
    };

    fetchBestsellersProducts();
  }, []);
  // Fetch callouts
  useEffect(() => {
    const fetchCallouts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_user_selection/');
        const data = response.data;
        // Map the data to match the format of the static callouts
        const formattedData = data.map(item => ({
          name: item.product_name,
          description: item.description || 'No description available', // Assuming description is part of the product serializer
          imageSrc: `https://terracharms.com${item.product_image}`, // Construct the image URL
          imageAlt: `${item.product_name} image`, // Set an alt text
          href: `/user/productpage/${pk}/${item.id}/`, // Assuming product ID is available in the data
        }));
        setCallouts(formattedData);
      } catch (error) {
        console.error('Error fetching callouts:', error);
      }
    };

    fetchCallouts();
  }, []);

  // Fetch user banners
  const fetchUserBanners = async () => {
  axios.get(`https://terracharms.com/get_user_banner/`)
      .then(response => setUserBanners(response.data))
      .catch(error => console.error('Error fetching banners:', error));
};
useEffect(() => {
  axios.get('https://terracharms.com/get_user_promotion/')
    .then(response => {
      const data = response.data;
      setPromotions(data);

      // Use the first promotion for headline and insight if available
      if (data.length > 0) {
        setHeadline(data[0].Headline || 'Discover the Art of Living');
        setInsight(data[0].Insight || 'This year, clearing and creating living spaces that are visually appealing and more attractive to the human eye.');
      }
    })
    .catch(error => {
      console.error('There was an error fetching the promotions!', error);
    });
}, []);

  // Fetch recent searched products
    useEffect(() => {
      const fetchRecentSearched = async () => {
        try {
          const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
          setProducts(response.data);
        } catch (error) {
          console.error('Error fetching recent searched products:', error);
        }
      };  
      fetchRecentSearched();
    }, [pk]);

    const handlerecentsearchedclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };

    const handlebestsellerclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };
    const handlelatestclick = (id) => {
      navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
    };

  useEffect(() => {
    fetchUserBanners();  // Ensure pk is provided in URL or set it dynamically
  },[]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    fade: true,
    arrows: true,
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 3000,  // Slide transition time in milliseconds
  };
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Show 7 products per slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    // <SlArrowRight />
  };


  // Handler to navigate to the AllProduct page for banners
  const handleBannerClick = (sub_category_name_id) => {
    navigate(`/user/allproduct/${pk}/${sub_category_name_id}`);
  };
  
  // Handler to navigate to the AllProduct page for promotions
  const handlePromotionClick = (sub_category_name_id) => {
    navigate(`/user/allproduct/${pk}/${sub_category_name_id}`);
  };

// const handleProductClick = (productId, productPk) => {
//   navigate(`/get_product/${productPk}/${productId}/`);
// };
const handleProductClick = async (id) => {
  try {
    // Hitting the Django API to get product data
    const response = await axios.get(`/get_product/${pk}/${id}/`);
    
    if (response.status === 200) {
      // If successful, navigate to the product page
      navigate(`/user/productpage/${pk}/${id}/`);
    } else {
      console.error("Failed to retrieve product details.");
    }
  } catch (error) {
    console.error("Error fetching product details:", error);
  }
};
useEffect(() => {
  if (searchTerm.trim() !== '') {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(
          `https://terracharms.com/user_search_get/${pk}/`,
          {
            params: {
              search: searchTerm
            }
          }
        );
        setProducts(response.data);

        // Redirect to the search results page
        navigate(`/user/allproducts/${pk}/`);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults();
  }
}, [searchTerm, pk, navigate, setProducts]);

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
};
  return (
    <>
    <Navbar onSearch={setSearchTerm} />
 
    <section className="homeslider bg-grey-100">
  <div className="container-h-90 w-100">
    <Slider {...settings}>
      {userBanners.length > 0 ? (
        userBanners.map((banner) => (
          <div
            key={banner.sub_id} // Ensure unique key
            className="item h-80 cursor-pointer"
            onClick={() => handleBannerClick(banner.sub_category_name_id)} // Pass sub_id
          >
            <img
              src={`https://terracharms.com${banner.Banner_image}`}
              className="w-100 h-80"
              alt={`Banner ${banner.sub_category_name_id}`}
            />
          </div>
        ))
      ) : (
        <p>No banners available</p>
      )}
    </Slider>
  </div>

  <div
  className="relative overflow-hidden mt-4 mb-8"
  
>
  <div className="pb-40 pt-16">
    <div className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      <div className="sm:max-w-lg">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {headline}
        </h1>
        <p className="mt-4 text-xl text-gray-500">
          {insight}
        </p>
      </div>
      <div>
      <div
  aria-hidden="true"
  className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
>
  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
    <div className="flex items-center space-x-6 lg:space-x-8">
      {promotions.length > 0 ? (
        promotions.map((promotion, index) => (
          <div
            key={index}
            className="grid grid-cols-3 gap-x-6 gap-y-10 mb-1 h-full w-full"
          >
            {[
              promotion.promotion_image1,
              promotion.promotion_image2,
              promotion.promotion_image3,
              promotion.promotion_image4,
              promotion.promotion_image5,
            ].map((image, idx) => (
              image ? (
                <div
                  key={idx}
                  className="relative h-48 w-40 overflow-hidden rounded-lg border border-gray-500 p-2 transition-transform duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => handlePromotionClick(promotion.sub_category_name_id)}
                >
                  <img
                    alt={`Promotion ${idx + 1}`}
                    src={`https://terracharms.com${image}`}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              ) : null
            ))}
          </div>
        ))
      ) : (
        <p>No promotions available</p>
      )}
    </div>
  </div>
</div>

{promotions.length > 0 && (
  <a
    href="#"
    className="inline-block mt-16 rounded-md border border-transparent bg-blue-900 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
    onClick={(e) => {
      e.preventDefault();
      handlePromotionClick(promotions[0].sub_category_name_id);
    }}
  >
    Shop Collection
          </a>
        )}
      </div>
    </div>
  </div>
</div>


<div className="relative overflow-hidden  bg-white mb-6">
  <div className="pt-6 bg-white">
    <h2 className="text-3xl font-bold text-left ml-4 text-gray-900">Latest Products</h2>
    <div className="mt-6 mb-12 grid grid-row-1 sm:grid-cols-1 lg:grid-row-4">
      <Slider {...settings1}>
        {latestProducts.length > 0 ? (
          latestProducts.map((product) => (
            <div key={product.id} className="group relative px-3 bg-white"
              onClick={() => handlelatestclick(product.id, product.pk)}
            >
              <div className="box-border p-2 border-1 border-black-500  rounded-md">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="mt-2 mb-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href="">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.product_name}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No latest products available</p>
        )}
      </Slider>
    </div>
  </div>
</div>


<div className="relative overflow-hidden bg-white">
  <div className="pt-bg-white">
    <h2 className="text-3xl font-bold text-gray-900 text-left ml-4 pt-4">Best Seller on Domestic Harmony</h2>
    <div className="mt-6 mb-12 grid grid-row-1 sm:grid-cols-1 lg:grid-row-4">
      <Slider {...settings1}>
        {bestsellersProducts.length > 0 ? (
          bestsellersProducts.map((product) => (
            <div key={product.id} className="group relative px-3 bg-white"
              onClick={() => handlebestsellerclick(product.id, product.pk)}
            >
              {/* Box around the image with padding and border */}
              <div className="box-border p-2 border-1 box-border-grey-400 rounded-md">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>
              </div>

              <div className="mt-2 mb-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href="">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.product_name}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No bestsellers available</p>
        )}
      </Slider>
    </div>
  </div>
</div>


<div className="mt-2 bg-white">
  <div className="px-4 sm:px-6 lg:px-8 pt-2 mt-8 pb-4 bg-white">
    <div className="mt-4 mb-4 lg:max-w-none">
      <h2 className="text-3xl font-bold text-gray-900 text-left ml-2">Top Selection</h2>
      <div className="mt-6 mb-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
        {callouts.length > 0 ? (
          callouts.map((callout) => (
            <div key={callout.name} className="group relative bg-white"
              onClick={() => handleProductClick(callout.id, callout.pk)}
            >
              <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 ">
                <img
                  alt={callout.imageAlt}
                  src={callout.imageSrc}
                  className="h-full w-full object-cover object-center transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                />
              </div>
              <h3 className="mt-6 text-sm text-gray-500">
                <a href={callout.href}>
                  <span className="absolute inset-0" />
                  {callout.name}
                </a>
              </h3>
              <p className="text-base font-semibold text-gray-900">{callout.description}</p>
            </div>
          ))
        ) : (
          <p>No selections available.</p>
        )}
      </div>
    </div>
  </div>
</div>

<div className="bg-white mt-6">
  <div className="pt-2">
    <h2 className="text-2xl font-bold text-gray-900 text-left ml-2">You Also May Like</h2>
    <div className="mt-6 mb-14">
      <Slider {...settings1}>
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="group relative px-3 bg-white"
              onClick={() => handlerecentsearchedclick(product.id, product.pk)}
            >
              {/* Box around the image with padding and border */}
              <div className="box-border p-2 border-1 border-grey rounded-md">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>
              </div>

              <div className="mt-2 mb-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href="">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.product_name}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No similar products available</p>
        )}
      </Slider>
    </div>
  </div>
</div>


</section>

    </>
  );
};
export default Front;
