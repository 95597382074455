import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountSettings.css';
import { useParams } from 'react-router-dom';

const AccountSettings = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile_no: '',
    email: '',
  });
  const { pk } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`http://13.127.147.104/user_profile/${pk}/`);
        const data = response.data;
        setFormData({
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          Mobile_no: data.Mobile_no || '',
          email: data.email || '',
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [pk]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`http://13.127.147.104/edit_user_profile/${pk}/`, formData);
      console.log('Edit Response:', response.data);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className='accountsettings'>
      <h1 className='mainhead1'>Personal Information</h1>

      <div className='form'>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='first_name'>First Name <span>*</span></label>
            <input
              type='text'
              name='first_name'
              id='first_name'
              value={formData.first_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='form-group'>
            <label htmlFor='last_name'>Last Name <span>*</span></label>
            <input
              type='text'
              name='last_name'
              id='last_name'
              value={formData.last_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='form-group'>
            <label htmlFor='Mobile_no'>Phone/Mobile <span>*</span></label>
            <input
              type='text'
              name='Mobile_no'
              id='Mobile_no'
              value={formData.Mobile_no}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='form-group'>
            <label htmlFor='email'>Email <span>*</span></label>
            <input
              type='email'
              name='email'
              id='email'
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <button type='submit' className='mainbutton1'>
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountSettings;
