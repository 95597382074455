import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import AShiprocket from "../components/AShiprocket";

const ASShiprocket = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
       <AShiprocket/>
      </main>
    </>
  );
};

export default ASShiprocket;