import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Orders = () => {
  const { pk , id } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_seller_orders/${pk}/`);
        console.log("Orders data:", response.data); // Debugging line
        setOrders(response.data);
      } catch (error) {
        console.error("There was an error fetching the orders!", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [pk]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading orders</div>;

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Total</th>
          <th scope="col">Payment Method</th>
          <th scope="col">Date</th>
          <th>Status</th>
          <th scope="col" className="text-end">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order, index) => (
          <tr key={index}>
            <td>
              <b>{order.Product_Name}</b>
            </td>
            <td>{order.User_email}</td>
            <td>₹{order.Price}</td>
            <td>{order.Payment_status}</td>
            <td>{new Date(order.Time).toLocaleDateString()}</td>
            <td>
              <span className={`badge btn-${order.Status === 'Delivered' ? "success" : "dark"}`}>
                {order.Status}
              </span>
            </td>
            <td className="d-flex justify-content-end align-item-center">
              <Link to={`/seller/order/${pk}/${order.id}`} className="text-success">
                <i className="fas fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Orders;
