import NavBar from "../features/navbar/Navbar";
import AllProduct from "../user/COMPONENTS/Product/AllProduct";
import Footer from "../features/footer/Footer";
// import Front from "../features/frontpage/Front";


function Home(){
    return(
        <div>
            
             <AllProduct></AllProduct>
             {/* <Front></Front>*/}             
             <Footer></Footer>
        </div>
    );

}

export default Home;