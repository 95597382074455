import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const LatestOrder = () => {
  const { pk } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_new_seller_orders/${pk}/`);
        setOrders(response.data);
      } catch (error) {
        console.error("There was an error fetching the latest orders!", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [pk]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="card-body">
      <h5 className="card-title">Recent orders</h5>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>
                  <b>{order.Product_Name}</b>
                </td>
                <td>{order.User_email}</td>
                <td>₹{order.Price}</td>
                <td>
                  <span className={`badge rounded-pill alert-${order.Payment_status ? "success" : "danger"}`}>
                    {order.Payment_status ? "Paid" : "Not paid"}
                  </span>
                </td>
                <td>{new Date(order.Time).toLocaleDateString()}</td>
                <td>{order.Status}</td>
                <td className="d-flex justify-content-end align-item-center">
                  <Link to={`/seller/order/${pk}/${order.id}`} className="text-success">
                    <i className="fas fa-eye"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LatestOrder;
