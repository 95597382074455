
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SUserAddress.css';
import { useParams } from 'react-router-dom';

const SUserAddress = () => {
    const { pk } = useParams(); // Make sure 'pk' is being passed from the route params
    const [showAddEditForm, setShowAddEditForm] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [formData, setFormData] = useState({
        address: '',
        city: '',
        country: '',
        state: '',
        zipcode: '',
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editAddressId, setEditAddressId] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                // Fetching the seller addresses
                const addressResponse = await axios.get(`https://terracharms.com/seller_address/${pk}/`);
                setAddresses(addressResponse.data);
                
                // Fetching the default address
                const defaultResponse = await axios.get(`https://terracharms.com/seller_default_address/${pk}/`);
                setDefaultAddress(defaultResponse.data);
                
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError('Failed to fetch data. Please try again later.');
                setLoading(false);
            }
        };

        fetchData();
    }, [pk]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            if (isEditing) {
                // Update address API call
                await axios.patch(`https://terracharms.com/edit_seller_address/${pk}/${editAddressId}/`, formData);
                alert('Address updated successfully');
            } else {
                // Add address API call
                await axios.post(`https://terracharms.com/add_seller_address/${pk}/`, formData);
                alert('Address added successfully');
            }
            
            // Reset the form after successful save
            setShowAddEditForm(false);
            setIsEditing(false);
            setEditAddressId(null);
            setFormData({
                address: '',
                city: '',
                country: '',
                state: '',
                zipcode: '',
            });

            // Fetch the updated list of addresses
            const addressResponse = await axios.get(`https://terracharms.com/seller_address/${pk}/`);
            setAddresses(addressResponse.data);
        } catch (error) {
            console.error('Error saving address:', error);
            alert('Failed to save address');
        }
    };

    const handleEdit = (address) => {
        setFormData(address);
        setIsEditing(true);
        setEditAddressId(address.id); // Storing the address id to know which address to update
        setShowAddEditForm(true);
    };

    const handleDelete = async (id) => {
        try {
            // Deleting address API call
            await axios.delete(`https://terracharms.com/delete_seller_address/${pk}/${id}/`);
            alert('Address deleted successfully');
            
            // Fetch updated addresses after deletion
            const addressResponse = await axios.get(`https://terracharms.com/seller_address/${pk}/`);
            setAddresses(addressResponse.data);
        } catch (error) {
            console.error('Error deleting address:', error);
            alert('Failed to delete address');
        }
    };

    const toggleAddEditForm = () => {
        setShowAddEditForm(!showAddEditForm);
        setIsEditing(false);
        setEditAddressId(null);
        setFormData({
            address: '',
            city: '',
            country: '',
            state: '',
            zipcode: '',
        });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className='useraddress'>
            <h1 className='mainhead1'>Your Address</h1>
            <div className='default-address'>
                <h2 className='mainhead2'>Default Address</h2>
                <div className='address'>
                    <span>{defaultAddress.address}</span>,
                    <span>{defaultAddress.city}</span>,
                    <span>{defaultAddress.state}</span>,
                    <span>{defaultAddress.zipcode}</span>,
                    <span>{defaultAddress.country}</span>
                </div>
            </div>
            <div className='addressin'>
                {addresses.map((item, index) => (
                    <div className='address' key={index}>
                        <span>{item.address}</span>,
                        <span>{item.city}</span>,
                        <span>{item.state}</span>,
                        <span>{item.zipcode}</span>,
                        <span>{item.country}</span>
                        <div className='editbtn' onClick={() => handleEdit(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 3.487a2.25 2.25 0 013.182 3.183L7.265 19.45a4.5 4.5 0 01-1.73 1.047l-4.5 1.35a.75.75 0 01-.92-.92l1.35-4.5a4.5 4.5 0 011.047-1.73L16.862 3.487z" />
                            </svg>
                        </div>
                        <div className='delbtn' onClick={() => handleDelete(item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
            <div className='addnewbtn' onClick={toggleAddEditForm}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
            {showAddEditForm && (
                <div className='addnew'>
                    <h1 className='mainhead1'>{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
                    <div className='form'>
                        <div className='form-group'>
                            <label htmlFor='address'>Address</label>
                            <input type="text" name="address" value={formData.address} onChange={handleInputChange} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='city'>City</label>
                            <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='state'>State</label>
                            <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='zipcode'>Zipcode</label>
                            <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='country'>Country</label>
                            <input type="text" name="country" value={formData.country} onChange={handleInputChange} />
                        </div>
                    </div>
                    <button className='mainbutton1' onClick={handleSave}>{isEditing ? 'Save Changes' : 'Add Address'}</button>
                </div>
            )}
        </div>
    );
}

export default SUserAddress;
