import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import Promotion from "../components/promotion";

const PromoPage = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <Promotion />
      </main>
    </>
  );
};

export default PromoPage;
