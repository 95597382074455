import React, { useEffect, useState } from "react";
import { Link,useParams } from "react-router-dom";
import axios from "axios";

const CategoriesTable = ({ selectedCategoryId }) => {
  const [categories, setCategories] = useState([]);
  const {pk}=useParams();

  useEffect(() => {
    fetchCategories();
  }, []);
  

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://terracharms.com/get_category/");
      const categoriesData = response.data;

      // Fetch sub-categories for each category
      const categoriesWithSubcategories = await Promise.all(
        categoriesData.map(async (category) => {
          const subCategoriesResponse = await axios.get(
            `https://terracharms.com/get_sub_categories_by_cat/${category.id}/`
          );
          return {
            ...category,
            subcategories: subCategoriesResponse.data,
          };
        })
      );

      setCategories(categoriesWithSubcategories);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleDeleteCategory = async (pk,id) => {
    try {
      const response = await axios.delete(`https://terracharms.com/delete_category/${pk}/${id}/`);
      console.log(response.data); // Optional: Log the response for debugging
      // Refresh categories after deletion
      fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  // Filter subcategories based on selectedCategoryId (if provided)
  const filteredCategories = selectedCategoryId
    ? categories.filter((category) => category.id === selectedCategoryId)
    : categories;

  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Sub-Categories</th>
            <th className="text-end">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories.map((category, index) => (
            <tr key={index}>
              <td>{category.id}</td>
              <td><b>{category.category_name}</b></td>
              <td>{category.description}</td>
              <td>
                {category.subcategories && category.subcategories.length > 0 ? (
                  <ul>
                    {category.subcategories.map((subCategory, subIndex) => (
                      <li key={subIndex}>
                        <strong>{subCategory.sub_category_name
                        }</strong>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <li>No sub-categories</li>
                )}
              </td>
              <td className="text-end">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="btn btn-light"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </Link>
                  <div className="dropdown-menu">
                  <Link
                      className="dropdown-item"
                      to={`/admin/category/editcategory/${pk}/${category.id}`}
                    >
                      Edit info
                    </Link>
                    <button className="dropdown-item text-danger" onClick={() => handleDeleteCategory( pk,category.id)}>
                        Delete Sub Category
                      </button>
                    <button className="dropdown-item text-danger" onClick={() => handleDeleteCategory( pk,category.id)}>
                        Delete Category
                      </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriesTable;
