import Seller from "../features/seller/components/Seller"
import Footer from "../features/footer/Footer";
function SellerPage(){
    return(
        <div>
         <Seller></Seller> 
         <Footer></Footer>
        </div>
    );

}

export default SellerPage;