import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const CreateCategory = ({ fetchCategories }) => {
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [subCategories, setSubCategories] = useState([{ name: '', description: '' }]);
  const csrftoken = Cookies.get('csrftoken');
  const {pk}=useParams();

  const handleAddSubCategory = () => {
    setSubCategories([...subCategories, { name: '' }]);
  };

  const handleSubCategoryChange = (index, field, value) => {
    const newSubCategories = [...subCategories];
    newSubCategories[index][field] = value;
    setSubCategories(newSubCategories);
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('category_name', categoryName);
    formData.append('description', description);
    formData.append('category_image', image);

    try {
      const response = await axios.post(`https://terracharms.com/create_category/1/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrftoken,
        },
      });

      const categoryId = response.data.id;

      await Promise.all(subCategories.map(async (subCategory) => {
        await axios.post(`https://terracharms.com/create_sub_category/${pk}/${categoryId}/`, {
          sub_category_name: subCategory.name,
         
        }, {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        });
      }));

      fetchCategories(); // Refresh the categories list
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="col-md-12 col-lg-4">
      <form onSubmit={handleCategorySubmit}>
        <div className="mb-4">
          <label htmlFor="category_name" className="form-label">Name</label>
          <input
            type="text"
            placeholder="Type here"
            className="form-control py-3"
            id="category_name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Images</label>
          <input
            className="form-control"
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Description</label>
          <textarea
            placeholder="Type here"
            className="form-control"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="form-label">Sub-Categories</label>
          {subCategories.map((subCategory, index) => (
            <div key={index} className="mb-2">
              <input
                type="text"
                placeholder="Sub-category Name"
                className="form-control mb-2"
                value={subCategory.name}
                onChange={(e) => handleSubCategoryChange(index, 'name', e.target.value)}
              />
             
            </div>
          ))}
          <button type="button" className="btn btn-secondary mt-2" onClick={handleAddSubCategory}>
            Add Sub-Category
          </button>
        </div>

        <div className="d-grid">
          <button className="btn btn-primary py-3">Create category</button>
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;


