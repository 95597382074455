import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Product from "./Product"; // Adjust import as needed

const MainProducts = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("All category");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  
  const { pk } = useParams(); // Assuming you pass sellerId as a URL parameter

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://terracharms.com/get_category/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    fetchAllProducts();
  }, [pk]);

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get(`https://terracharms.com/admin_product/${pk}/`);
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching the products!", error);
      setProducts([]); // Ensure products is always an array
    }
  };

  const fetchProductsByCategory = async (selectedCategory) => {
    console.log("Selected Category:", selectedCategory); // Log selected category to console
    try {
      const response = await axios.get(`https://terracharms.com/categoryproductsadmin/${pk}/`, {
        params: { Category_name: selectedCategory },
      });
      console.log("Response data:", response.data); // Log response data to console
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching products by category!", error);
      setProducts([]); // Ensure products is always an array
    }
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    if (selectedCategory === "All category") {
      fetchAllProducts();
    } else {
      fetchProductsByCategory(selectedCategory);
    }
  };

  const handleSearch = async (searchTerm) => {
    setSearchTerm(searchTerm);
    try {
      const response = await axios.get(`https://terracharms.com/search_admin/${pk}/`, {
        params: { query: searchTerm },
      });
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching the search results!", error);
      setProducts([]); // Ensure products is always an array
    }
  };
  const handleEditClick = (productId) => {
    navigate(`/admin/product/edit/${pk}/${productId}`);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Products</h2>
        <div>
          <Link to={`/admin/addproduct/${pk}`} className="btn btn-primary">
            Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white">
          <div className="row gx-3 py-3">
          <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="search"
                placeholder="Search..."
                className="form-control p-2"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select
                className="form-select"
                value={category}
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value="All category">All category</option>
                {categories.map((cat, id) => (
                  <option key={id} value={cat.category_name}>
                    {cat.category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </header>
      </div>
      <div className="card-body">
        <div className="container mt-4">
          <h2 className="mb-4">Product List</h2>
          <div className="row">
            {Array.isArray(products) && products.length > 0 ? (
              products.map((product, index) => (
                <Product
                  key={product.id}
                  product={product}
                  index={index}
                  handleEditClick={handleEditClick}
                />
              ))
            ) : (
              <p>No products found.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainProducts;
