import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import SMainCategories from "../components/Categories/MainCategories";

const SCategoriesScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <SMainCategories />
      </main>
    </>
  );
};

export default SCategoriesScreen;
