import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TopTotal from "./TopTotal";
import LatestOrder from "./LatestOrder";
import axios from "axios";

const Main = () => {
  const { pk } = useParams();
  const [data, setData] = useState({ total_sales: 0, total_orders: 0, total_products: 0 });

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/seller_dash/${pk}/`);
        setData(response.data);
      } catch (error) {
        console.error("There was an error fetching the seller data!", error);
      }
    };

    fetchSellerData();
  }, [pk]);

  return (
    <>
      <section className="content-main">
        <div className="content-header">
          <h2 className="content-title">Dashboard</h2>
        </div>
        <TopTotal data={data} />
       <div className="card mb-4 shadow-sm">
          <LatestOrder data={data.orders} />
        </div>
      </section>
    </>
  );
};

export default Main;
