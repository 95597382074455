import AdminSignup from "../features/auth/components/AdminSignup";

function AdminSignupPage() {
    return ( 
        <div>
            <AdminSignup></AdminSignup>
        </div>
     );
}

export default AdminSignupPage; 