import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectCount } from '../authSlice';

export default function Signup() {
  const count = useSelector(selectCount);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  
  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.post('https://terracharms.com/send_seller_otp/', {
        Name: name,
        email: email,
        Mobile_no: mobileNumber,
        aadhar_number: aadharNumber,
        pan_number: panNumber
      });
      
      if (response.status === 200) {
        const pk  = response.data.id;
        navigate(`/sellerotp2/${pk}`);
      } else {
        setError('Failed to create account.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const isLoginDisabled = !name || !email || !mobileNumber || !aadharNumber || !panNumber;
  const isValidName = /^[A-Za-z\s]+$/.test(name);
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidMobileNumber = /^\d{10}$/.test(mobileNumber);
  const isValidAadharNumber = /^\d{12}$/.test(aadharNumber);
 
  const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) || value === '') {
      setMobileNumber(value);
    }
  };

  return (
    <div className="mx-auto mt-12 bg-white max-w-2xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
          alt="StarkMart"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Welcome to Startrack
          <p>Create your account to start selling</p>
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSignup}>
          <div>
            <label htmlFor="Name" className="block text-sm font-medium leading-6 text-gray-900">
            Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="mobileNumber" className="block text-sm font-medium leading-6 text-gray-900">
              Mobile Number
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="mobileNumber"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email Id
            </label>
            <div className="mt-2">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="aadharNumber" className="block text-sm font-medium leading-6 text-gray-900">
              Aadhar Number
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="aadharNumber"
                value={aadharNumber}
                onChange={(e) => setAadharNumber(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {!isValidAadharNumber && aadharNumber !== '' && (
                <p className="text-red-500 text-sm">Please enter a valid 12-digit Aadhar number.</p>
              )}
            </div>
          </div>

          <div>
            <label htmlFor="panNumber" className="block text-sm font-medium leading-6 text-gray-900">
              PAN Number
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="panNumber"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {!isValidPanNumber && panNumber !== '' && (
                <p className="text-red-500 text-sm">Please enter a valid PAN number.</p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoginDisabled || loading}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? 'Creating Account...' : 'Create Account'}
            </button>
          </div>

          {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already a Seller?{' '}
          <Link to="/sellerlogin" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Log In
          </Link>
        </p>
      </div>
    </div>
  );
}
