import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./promotion.css";
import { useParams } from "react-router-dom";

const Promotion = ({ productId }) => {
  const [promotions, setPromotions] = useState([]);
  const [newPromotion, setNewPromotion] = useState({
    promotion_image1: null,
    promotion_image2: null,
    promotion_image3: null,
    promotion_image4: null,
    promotion_image5: null,
    Headline: '',
    Insight: '',
  });
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const { pk } = useParams();

  useEffect(() => {
    axios.get(`https://terracharms.com/get_promotion/${pk}/`)
      .then(response => {
        console.log('Fetched promotions:', response.data);
        setPromotions(response.data || []);
      })
      .catch(error => console.error('Error fetching promotions:', error));

    axios.get('https://terracharms.com/get_sub_categories/')
      .then(response => {
        console.log('Fetched subcategories:', response.data);
        setSubcategories(response.data || []);
      })
      .catch(error => console.error('Error fetching subcategories:', error));
  }, [pk]);

  const handleImageChange = (e, field) => {
    setNewPromotion({ ...newPromotion, [field]: e.target.files[0] });
  };

  const handlePromotionImageChange = (e, promotionIndex, field) => {
    const updatedPromotions = [...promotions];
    updatedPromotions[promotionIndex][field] = e.target.files[0];
    setPromotions(updatedPromotions);
  };

  const handleTextChange = (e, field, promotionIndex = null) => {
    if (promotionIndex === null) {
      setNewPromotion({ ...newPromotion, [field]: e.target.value });
    } else {
      const updatedPromotions = [...promotions];
      updatedPromotions[promotionIndex][field] = e.target.value;
      setPromotions(updatedPromotions);
    }
  };

  const handleSave = (promotionIndex) => {
    const promotion = promotions[promotionIndex];
    if (!promotion) return;

    const formData = new FormData();
    for (let i = 1; i <= 5; i++) {
      const imageKey = `promotion_image${i}`;
      if (promotion[imageKey] instanceof File) {
        formData.append(imageKey, promotion[imageKey]);
      }
    }
    formData.append('Headline', promotion.Headline);
    formData.append('Insight', promotion.Insight);

    axios.put(`https://terracharms.com/update_promotion/${pk}/${promotion.id}/`, formData)
      .then(response => {
        const updatedPromotions = [...promotions];
        updatedPromotions[promotionIndex] = response.data;
        setPromotions(updatedPromotions);
      })
      .catch(error => console.error('Error updating promotion:', error));
  };

  const handleDelete = (promotionId) => {
    axios.delete(`https://terracharms.com/delete_promotion/${pk}/${promotionId}/`)
      .then(() => setPromotions(promotions.filter(promo => promo.id !== promotionId)))
      .catch(error => console.error('Error deleting promotion:', error));
  };

  const handleCreate = () => {
    const formData = new FormData();
    for (let i = 1; i <= 5; i++) {
      const imageKey = `promotion_image${i}`;
      if (newPromotion[imageKey]) {
        formData.append(imageKey, newPromotion[imageKey]);
      }
    }
    formData.append('Headline', newPromotion.Headline);
    formData.append('Insight', newPromotion.Insight);
    formData.append('sub_category_name', selectedSubcategory);

    axios.post(`https://terracharms.com/post_promotion/${pk}/`, formData)
      .then(response => {
        setPromotions([...promotions, response.data]);
        setNewPromotion({
          promotion_image1: null,
          promotion_image2: null,
          promotion_image3: null,
          promotion_image4: null,
          promotion_image5: null,
          Headline: '',
          Insight: '',
        });
        setSelectedSubcategory('');
      })
      .catch(error => console.error('Error creating promotion:', error));
  };

  return (
    <div className="promotion">
      <h2>Manage Promotions for Product</h2>

      {/* Create New Promotion */}
      <div className="new-promotion">
        <h3>Create New Promotion</h3>
        <div>
          <label htmlFor="selectedSubcategory">Select Subcategory:</label>
          <select
            id="sub_category_name"
            name="sub_category_name"
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select Subcategory</option>
            {Array.isArray(subcategories) && subcategories.map((subcategory, index) => (
              <option key={index} value={subcategory.sub_category_name}>
                {subcategory.sub_category_name}
              </option>
            ))}
          </select>
        </div>
        {[1, 2, 3, 4, 5].map((index) => (
          <div key={index}>
            <input
              type="file"
              onChange={(e) => handleImageChange(e, `promotion_image${index}`)}
            />
          </div>
        ))}
        <input
          type="text"
          value={newPromotion.Headline}
          onChange={(e) => handleTextChange(e, 'Headline')}
          placeholder="Headline"
        />
        <textarea
          value={newPromotion.Insight}
          onChange={(e) => handleTextChange(e, 'Insight')}
          placeholder="Insight Text"
        />
        <button className='font-bold bg-green-300 px-6 py-3 text-white rounded-md' onClick={handleCreate}>
          Create Promotion
        </button>
      </div>

      {Array.isArray(promotions) && promotions.length > 0 ? (
        promotions.map((promotion, promotionIndex) => (
          <div key={promotion.id} className="promotion-card">
            <h3>Promotion {promotionIndex + 1}</h3>
            {[1, 2, 3, 4, 5].map((index) => (
              <div key={index}>
                {promotion[`promotion_image${index}`] ? (
                  <div>
                    <img
                      src={`https://terracharms.com${promotion[`promotion_image${index}`]}`}
                      alt={`Promotion ${promotionIndex + 1} - Image ${index}`}
                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                    {/* <input
                      type="file"
                      onChange={(e) => handlePromotionImageChange(e, promotionIndex, `promotion_image${index}`)}
                      accept="image/*"
                    /> */}
                  </div>
                ) : (
                  <div>
                    <input
                      type="file"
                      onChange={(e) => handlePromotionImageChange(e, promotionIndex, `promotion_image${index}`)}
                      accept="image/*"
                    />
                  </div>
                )}
              </div>
            ))}
            <input
              type="text"
              value={promotion.Headline}
              onChange={(e) => handleTextChange(e, 'Headline', promotionIndex)}
              placeholder="Headline"
            />
            <textarea
              value={promotion.Insight}
              onChange={(e) => handleTextChange(e, 'Insight', promotionIndex)}
              placeholder="Insight Text"
            />
            {/* <button onClick={() => handleSave(promotionIndex)}>Save Changes</button> */}
            <button onClick={() => handleDelete(promotion.id)}>Delete Promotion</button>
          </div>
        ))
      ) : (
        <p>No promotions available.</p>
      )}
    </div>
  );
};

export default Promotion;
