import React  from "react";
import { Link, NavLink , useParams} from "react-router-dom";
const Sidebar = () => {
  const {pk} = useParams();
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to={`/sellerdash/${pk}/`} className="brand-wrap">
            <img
              src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
              style={{ height: "26" }}
              className="logo"
              alt="Ecommerce dashboard template"
            /> <span className="font-bold  ">The StarkMart</span>
          </Link>
        </div>

        <nav>
          <ul className="menu-aside">
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/sellerdash/${pk}/`}
                exact={true}
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/products/${pk}/`}
              >
                <i className="icon fas fa-shopping-bag"></i>
                <span className="text">Inventory</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/addproduct/${pk}/`}
              >
                <i className="icon fas fa-cart-plus"></i>
                <span className="text">Upload Catalog</span>
              </NavLink>
            </li>            
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/orders/${pk}/`}
              >
                <i className="icon fas fa-bags-shopping"></i>
                <span className="text">Orders</span>
              </NavLink>
            </li>
            
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
