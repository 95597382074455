// import React, { useState, useEffect } from "react";
// import { Link, useParams , useNavigate} from "react-router-dom";
// import { toast } from "react-toastify";
// import Footer from "../../../features/footer/Footer";
// import Navbar from "../../../features/navbar/Navbar";
// import axios from "axios"; // Import Axios
// import "./ProductPage.css";
// function ProductPage() {
//   const { pk, id } = useParams(); // Assuming you are using pk and id from the URL params
//   const [productdata, setProductdata] = useState({});
//   const [activeImg, setActiveImg] = useState({});
//   const [imageset, setImageset] = useState([]);
//   const [count, setcount] = React.useState(1)
//   const [products, setProducts] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate(); // Hook to programmatically navigate
//     useEffect(() => {
//       if (searchTerm.trim() !== '') {
//         const fetchSearchResults = async () => {
//           try {
//             const response = await axios.get(
//               `https://terracharms.com/user_search_get/${pk}/`,
//               {
//                 params: {
//                   search: searchTerm
//                 }
//               }
//             );
//             setProducts(response.data);
    
//             // Redirect to the search results page
//             navigate(`/user/allproducts/${pk}/`);
//           } catch (error) {
//             console.error('Error fetching search results:', error);
//           }
//         };
    
//         fetchSearchResults();
//       }
//     }, [searchTerm, pk, navigate, setProducts]);
    
//     const handleSearch = (e) => {
//       setSearchTerm(e.target.value);
//     };

//   useEffect(() => {
//     const getProductDataById = async () => {
//       try {
//         let response = await axios.get(
//           `https://terracharms.com/get_product/${pk}/${id}/`
//         );
//         if (!response.data) {
//           throw new Error("Failed to fetch product data");
//         }
//         let product = response.data;
//         setProductdata(product);
//         setActiveImg({ id: 1, image: product.product_image }); // Set the first image as active by default
//         setImageset([
//           { id: 1, image: product.product_image },
//           { id: 2, image: product.product_image2 },
//           { id: 3, image: product.product_image3 },
//         ]); // Setting up imageset array
//       } catch (error) {
//         console.error("Error fetching product data:", error);
//         toast.error("Failed to fetch product details");
//       }
//     };

//     getProductDataById();
//     window.scroll(0, 0); // Scroll to the top when component mounts
//   }, [pk, id]);

//   const handleImageClick = (item) => {
//     setActiveImg(item);
//   };

//   const addtoCart = async () => {
//     const formData = new FormData();
//     formData.append("product_id", productdata.product_id);
//     formData.append("quantity", 1); // Assuming adding one item at a time

//     try {
//       const response = await axios.patch(
//         `https://terracharms.com/add_to_cart/${pk}/${id}/`,
        
//       );
//       // console.log(response.data.id)
//       // console.log(response.data)
//       if (!response.data) {
//         throw new Error("Failed to add item to cart");
//       }

//       toast.success("Item added to cart");
//     } catch (error) {
//       console.error("Error adding item to cart:", error);
//       toast.error("Failed to add item to cart");
//     }
//   };

//   return (
//     <>
//       <Navbar onSearch={setSearchTerm} />
//       <div className="mt-6"></div>
//     <div className="productpage">
//       <div className="pc1">
//         <div className="c11">
//           <div className="imgset">
//             {imageset &&
//               imageset.map((item, index) => (
//                 <div
//                   className="imgsmall h-full w-full"
//                   key={item.id}
//                   onClick={() => handleImageClick(item)}
//                 >
//                   <img
//                     src={`https://terracharms.com/${item.image}`}
//                     alt={`Image ${item.id}`}
//                     className={activeImg.id === item.id ? "active" : ""}
//                   />
//                 </div>
//               ))}
//           </div>
//           <div className="imgbig">
//             <img src={`https://terracharms.com/${activeImg.image}`} alt={`Image ${activeImg.id}`} />
//           </div>
//                 </div>

//                 <div className='c12'>
//                     <h5 className='text-3xl font-bold text-gray-900'>{productdata.product_name}</h5>
//                     <div className='c121'>
//                         <p className='price'>
//                         ₹{productdata.discount_price* count}
//                             <span>₹{productdata.actual_price * count}</span>
//                         </p>
//                         </div>
//                     <div className="btncont">
//         <Link to={`/Cart/${pk}`}>
//         <button onClick={addtoCart}>Add to Cart</button> 
//         </Link>

//         <Link to={`/Cart/${pk}`}>
//           <button onClick={addtoCart}>Buy Now</button>
//         </Link>
//       </div>
//                     <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
//             {/* Description and details */}
//             <div>
//               <h1 className=" font-bold text-gray-900">Description</h1>

//               <div className="space-y-6">
//                 <p className="text-base text-gray-900">{productdata.description}</p>
//               </div>
//             </div>

//             <div className="mt-10">
//               <h1 className=" font-bold text-gray-900">Highlights</h1>

//               <div className="space-y-6">
//                 <p className="text-base text-gray-900">{productdata.Highlights}</p>
//               </div>
             
//             </div>
//             <div className="mt-10">
//               <h1 className="font-bold text-gray-900">Details</h1>
//               <div className=" space-y-6">
//                 <p className="text-gray-900">{productdata.Details}</p>
//           </div>
//                 </div>
//             </div>
            
//                </div>   
//                </div> 
//         <Footer></Footer>
//         </div>
//   </>
//   );
// }

// export default ProductPage
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../features/footer/Footer";
import Navbar from "../../../features/navbar/Navbar";
import axios from "axios";
import "./ProductPage.css";
import Slider from "react-slick";

function ProductPage() {
  const { pk, id } = useParams();
  const [productdata, setProductdata] = useState({});
  const [activeImg, setActiveImg] = useState({});
  const [imageset, setImageset] = useState([]);
  const [count, setCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(
            `https://terracharms.com/user_search_get/${pk}/`,
            {
              params: {
                search: searchTerm
              }
            }
          );
          // Redirect to the search results page
          navigate(`/user/allproducts/${pk}/`);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };

      fetchSearchResults();
    }
  }, [searchTerm, pk, navigate]);

  useEffect(() => {
    const getProductDataById = async () => {
      try {
        let response = await axios.get(
          `https://terracharms.com/get_product/${pk}/${id}/`
        );
        if (!response.data) {
          throw new Error("Failed to fetch product data");
        }
        let product = response.data;
        setProductdata(product);
        setActiveImg({ id: 1, image: product.product_image });
        setImageset([
          { id: 1, image: product.product_image },
          { id: 2, image: product.product_image2 },
          { id: 3, image: product.product_image3 },
        ]);
      } catch (error) {
        console.error("Error fetching product data:", error);
        toast.error("Failed to fetch product details");
      }
    };

    getProductDataById();
    window.scroll(0, 0);
  }, [pk, id]);
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Show 7 products per slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    // <SlArrowRight />
  };
  useEffect(() => {
    const fetchRecentSearched = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching recent searched products:', error);
      }
    };  
    fetchRecentSearched();
  }, [pk]);

  const handlerecentsearchedclick = (id) => {
    navigate(`/user/productpage/${pk}/${id}/`); // navigate to AllProduct page with the subcategory ID
  };

  const handleImageClick = (item) => {
    setActiveImg(item);
  };

  const addtoCart = async () => {
    const formData = new FormData();
    formData.append("product_id", productdata.product_id);
    formData.append("quantity", 1);

    try {
      const response = await axios.patch(
        `https://terracharms.com/add_to_cart/${pk}/${id}/`,
        formData
      );
      if (!response.data) {
        throw new Error("Failed to add item to cart");
      }
      toast.success("Item added to cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: productdata.product_name,
        url: `https://terracharms.com/get_product/${pk}/${id}/`
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert('Sharing is not supported on this browser.');
    }
  };

  return (
    <>
      <Navbar onSearch={setSearchTerm} />
      <div className="mt-6"></div>
      <div className="productpage">
        <div className="pc1">
          <div className="c11">
            <div className="imgset">
              {imageset &&
                imageset.map((item) => (
                  <div
                    className="imgsmall h-full w-full"
                    key={item.id}
                    onClick={() => handleImageClick(item)}
                  >
                    <img
                      src={`https://terracharms.com/${item.image}`}
                      alt={`Image ${item.id}`}
                      className={activeImg.id === item.id ? "active" : ""}
                    />
                  </div>
                ))}
            </div>
            <div className="imgbig">
              <div className="zoom-container">
                <img
                  src={`https://terracharms.com/${activeImg.image}`}
                  alt={`Image ${activeImg.id}`}
                  className="active-image"
                />
                <button className="share-button" onClick={handleShare}>
                <i className="fas fa-share-alt"></i> {/* FontAwesome Share Icon */}
                </button>
              </div>
            </div>
          </div>

          <div className='c12'>
            <h5 className='text-3xl font-bold text-gray-900'>{productdata.product_name}</h5>
            <div className='c121'>
              <p className='price'>
                ₹{productdata.discount_price * count}
                <span>₹{productdata.actual_price * count}</span>
              </p>
            </div>
            <div className="btncont">
              <Link to={`/Cart/${pk}`}>
                <button onClick={addtoCart}>Add to Cart</button>
              </Link>

              <Link to={`/Cart/${pk}`}>
                <button onClick={addtoCart}>Buy Now</button>
              </Link>
            </div>
            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              <div>
                <h1 className="font-bold text-gray-900">Description</h1>
                <div className="space-y-6">
                  <p className="text-base text-gray-900">{productdata.description}</p>
                </div>
              </div>

              <div className="mt-10">
                <h1 className="font-bold text-gray-900">Highlights</h1>
                <div className="space-y-6">
                  <p className="text-base text-gray-900">{productdata.Highlights}</p>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="font-bold text-gray-900">Details</h1>
                <div className="space-y-6">
                  <p className="text-gray-900">{productdata.Details}</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="pt-2">
    <h2 className="text-2xl font-bold text-gray-900 text-left ml-2">You Also May Like</h2>
    <div className="mt-6 mb-14">
      <Slider {...settings1}>
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="group relative px-3 bg-white"
              onClick={() => handlerecentsearchedclick(product.id, product.pk)}
            >
              {/* Box around the image with padding and border */}
              <div className="box-border p-2 border-1 border-grey rounded-md">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>
              </div>

              <div className="mt-2 mb-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href="">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.product_name}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No similar products available</p>
        )}
      </Slider>
    </div>
  </div>
        <Footer />
      </div>
    </>
  );
}

export default ProductPage;
