import React from "react";
import { Link, useParams } from "react-router-dom";
import $ from "jquery"; // Ensure you have jQuery installed
import axios from 'axios';
import './Header.css'; // Import CSS file

const Header = () => {
  const { pk } = useParams(); // Assuming pk is used correctly here

  // Function to handle logout
  const handleLogout = async () => {
    try {
      const response = await axios.patch(`https://terracharms.com/admin_logout/${pk}/`);
      
      if (response.status === 200) {
        window.location.href = '/admin/login'; // Redirect to seller login page after logout
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle the error as needed
    }
  };

  // Initialize jQuery plugins using useEffect
  React.useEffect(() => {
    // Offcanvas toggle click handler
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    // Minimize sidebar click handler
    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // Minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    <header className="main-header navbar">
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile me-auto"
          data-trigger="#offcanvas_aside"
        >
          <i className="md-28 fas fa-bars"></i>
        </button>
        <ul className="nav">
          <li className="dropdown nav-item">
            <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
              <img
                className="img-xs rounded-circle ;"
                src="/images/user.png"
                alt="User"
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-end ">
              <Link className="dropdown-item" to={`/admin/Profile/${pk}`}>
                My profile
              </Link>
              <button className="dropdown-item text-danger" onClick={handleLogout}>
                Exit
              </button>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
