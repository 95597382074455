import SellerSignup from "../features/auth/components/SellerSignup";

function SellerSignupPage() {
    return ( 
        <div>
            <SellerSignup></SellerSignup>
        </div>
     );
}

export default SellerSignupPage; 